import { Editor, Extension } from "@tiptap/core";
import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { getBlockNodeHasContent } from "../lib";
import { BLOCK_DEPTH } from "../nodes/DraftBlockNode";

export interface PlaceholderOptions {
  placeholderClass: string;
}

const createPlugin = (editor: Editor) =>
  new Plugin({
    props: {
      decorations: ({ doc, selection }) => {
        if (!selection.empty || !editor.view.hasFocus()) {
          return;
        }

        const selectedDraftBlock = selection.$to.node(BLOCK_DEPTH);
        if (!selectedDraftBlock) {
          return;
        }

        const blockHasContent = getBlockNodeHasContent(selectedDraftBlock);
        if (blockHasContent) {
          return;
        }

        const from = selection.$to.start(2) - 1;
        const to = selection.$to.end(2) + 1;

        const decoration = Decoration.node(from, to, {
          class: "show-placeholder",
        });

        return DecorationSet.create(doc, [decoration]);
      },
    },
  });

/**
 * Adds a class to the paragraph that the cursor is in if there
 * is no text in the block.
 */
const PlaceholderExtension = Extension.create<PlaceholderOptions>({
  name: "placeholder",
  addProseMirrorPlugins() {
    return [createPlugin(this.editor)];
  },
});

export default PlaceholderExtension;
