import { IFProject } from "@shared/types/Project";
import { IFProjectSummary, UpsertGroup } from "@shared/types/http/project";
import http from ".";
import { UserInvitation } from "../components/permissions/InviteUsersInput/types";
import { createHttpRequest } from "./lib/createHttpRequest";

const ROOT = "/api/projects";

export default {
  upsertGroup: async (arg: { projectId: string; groupId: string; data: UpsertGroup.Request["body"] }) => {
    const url = `${ROOT}/${arg.projectId}/groups/${arg.groupId}`;
    const { data } = await http.put<UpsertGroup.Response["body"]>(url, arg.data);

    return UpsertGroup.response.body.parse(data);
  },
  put: {
    updateProject: {
      url: (projectId: string) => `${ROOT}/${projectId}`,
      body: ({ name }: { name: string }) => ({ name }),
    },
    updateFeatureFlag: {
      url: (projectId: string) => `${ROOT}/${projectId}/feature-flags`,
      body: ({ flagName, enabled }: { flagName: string; enabled: boolean }) => ({
        flagName,
        enabled,
      }),
    },
  },
  get: {
    all: {
      url: ROOT,
    },
    isLocked: {
      url: (projectId: string) => `${ROOT}/${projectId}/is-locked`,
    },
    branchInfo: {
      url: (projectId: string, shouldValidateFigma: boolean) =>
        `${ROOT}/${projectId}/branch-info${shouldValidateFigma ? "?validateFigma=true" : ""}`,
    },
    featureFlags: {
      url: (projectId: string) => `${ROOT}/${projectId}/feature_flags`,
    },
  },
  post: {
    create: {
      url: ROOT,
      body: ({ projectName, folderId }: { projectName: string; folderId: string | null }) => ({
        projectName,
        folderId,
      }),
    },
    createTextItem: {
      url: (projectId, groupId) => `${ROOT}/${projectId}/groups/${groupId}/textItem`,
      body: ({ text, status, notes, tags }: { text: string; status: string; notes?: string; tags?: string[] }) => ({
        text,
        status,
        notes,
        tags,
      }),
    },
    invite: {
      url: (projectId) => `${ROOT}/${projectId}/invite`,
      body: ({
        invites,
        folderId,
        message,
      }: {
        invites: UserInvitation[];
        folderId: string | null;
        message: string;
      }) => ({ invites, folderId, message }),
    },
  },
  delete: {
    deleteTextItem: {
      url: (projectId, groupId, textItemId) => `${ROOT}/${projectId}/groups/${groupId}/textItem/${textItemId}`,
    },
  },
};

export const getProjectGroups = createHttpRequest<
  { projectId: string; groupIds?: string[] },
  { integrations: IFProject["integrations"]; groups: IFProject["groups"] }
>({
  method: "get",
  getUrl: (args) => {
    const { projectId, groupIds } = args;
    let url = `/doc/groups/${projectId}`;
    if (groupIds && groupIds.length > 0) {
      url += `?groupIds=${groupIds.join(",")}`;
    }
    return url;
  },
});

export const getProjectSummaries = createHttpRequest<{ projectIds: string[] } | void, IFProjectSummary[]>({
  method: "get",
  url: `${ROOT}/summaries`,
  getConfig: (args) => {
    if (!args) return {};
    return { params: { projectIds: args.projectIds } };
  },
});

export const updateProjectFeatureFlag = createHttpRequest<
  { projectId: string; flagName: string; enabled: boolean; flagMetadata?: object },
  void
>({
  method: "put",
  getUrl: (args) => `${ROOT}/${args.projectId}/feature-flags`,
  getConfig: (args) => ({
    data: { flagName: args.flagName, enabled: args.enabled, ...args.flagMetadata },
  }),
});
