import http from "@/http";
import { updateUserLoginMetrics } from "@shared/frontend/user";
import { IFRole, IFUserWithPermissions } from "@shared/types/User";
import { useEffect } from "react";
import useSegment from "../useSegment";
import useDeployedAuth from "./useDeployedAuth";
import useTrustedAuth from "./useTrustedAuth";

export const PREFERRED_LOGIN_PAGE_KEY = "preferred_login_page";

interface AuthUser extends IFUserWithPermissions {
  sub?: string;
  given_name?: string;
  billingRole: IFRole;
}

interface LoadingAuthState {
  loading: true;
  isAuthenticated: false;
  user: null;
  getTokenSilently: any;
  refreshUser: any;
  onDittoOverviewFinished: any;
  loginWithRedirect: (params: LoginWithRedirectParams) => any;
  logout: any;
}

interface AppState {
  targetUrl?: string;
}

export interface LoginWithRedirectParams {
  connection?: string | "Username-Password-Authentication";
  appState?: AppState;
  hasFigmaCorrelationKey?: boolean;
  workspaceID?: string | null;
  invitedRole?: any;
  enterpriseKey?: string;
  email?: string;
  plan?: string;
  isTest?: boolean;
  devTools?: boolean;
}

export interface UnauthenticatedAuthState {
  loading: false;
  isAuthenticated: false;
  user: null;
  getTokenSilently: any;
  refreshUser: any;
  onDittoOverviewFinished: any;
  loginWithRedirect: (params: LoginWithRedirectParams) => any;
  logout: any;
}

export interface AuthenticatedAuthState {
  loading: false;
  user: AuthUser;
  isAuthenticated: true;
  getTokenSilently: any;
  refreshUser: any;
  onDittoOverviewFinished: any;
  loginWithRedirect: (params: LoginWithRedirectParams) => any;
  logout: any;
}

export type AuthState = LoadingAuthState | UnauthenticatedAuthState | AuthenticatedAuthState;

const authHandler = process.env.IS_LOCAL === "true" ? useTrustedAuth : useDeployedAuth;

// NOTE: This hook should only be called once per app in the root component.
// You are most likely looking for the authenticated auth context which is stored in the `src/store/AuthenticatedAuth.tsx` file.
export const useRootAuth = (): AuthState => {
  const authState = authHandler();
  const segment = useSegment();

  useEffect(() => {
    if (!authState.user) {
      return;
    }

    updateUserLoginMetrics((url) => http.put(url), authState.user.createdAt);
    segment.track({
      event: "User Logged In",
      properties: {
        billing_role: authState.user.billingRole,
        job: authState.user.job,
        application: "web_app",
        email: authState.user.email,
      },
    });
  }, [authState.user]);
  return authState;
};
