import React from "react";

/**
 * Given some text to render and a search string, return a <span> containing the text, and wherever the renderText matches
 * the query text, return a <strong> wrapper around it
 */
export function getBoldedMatchingText(input: string, match: string) {
  if (!input) return "";
  if (!match) return input;

  let result: JSX.Element[] = [];

  let i = 0;
  let lastMatchEnd = 0;
  while (i < input.length - match.length + 1) {
    if (input.substring(i, i + match.length).toLowerCase() === match.toLowerCase()) {
      result.push(<React.Fragment key={i}>{input.substring(lastMatchEnd, i)}</React.Fragment>);
      result.push(<strong>{input.substring(i, i + match.length)}</strong>);
      i += match.length;
      lastMatchEnd = i;
    } else {
      i++;
    }
  }

  // one more push, for any remaining unmatched text
  result.push(<>{input.substring(lastMatchEnd)}</>);
  return result;
}
