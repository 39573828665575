import { SearchParamsFetcher } from "./components/ParamsFetcher";
import { ProjectNav } from "./components/ProjectNav";
import { Sidebar } from "./components/Sidebar";
import { TextList } from "./components/TextList";

function ProjectPage() {
  return (
    <div style={{ width: "calc(100% - 300px)", padding: 50 }}>
      <SearchParamsFetcher />
      <h1>Project Page</h1>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ProjectNav />
        <TextList />
        <Sidebar />
      </div>
    </div>
  );
}

export { ProjectPage };
