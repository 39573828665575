import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import style from "./style.module.css";

interface LabelProps {
  getLabelText: () => string;
  components?: {
    label?: () => React.ReactElement;
  };
  textLabelLeft?: React.ReactNode;
}

export const Label = (props: LabelProps) => {
  const { getLabelText, components, textLabelLeft } = props;

  const Container = useMemo(() => components?.label || DefaultLabelContainer, [components]);

  if (!components?.label && textLabelLeft) {
    return <>{textLabelLeft}</>;
  }

  return <Container>{getLabelText()}</Container>;
};

function DefaultLabelContainer({ children, ...props }) {
  return <h4 {...props}>{children}</h4>;
}

interface LabelRightProps {
  components?: {
    labelRight?: (p: { className?: string }) => React.ReactElement;
  };
  showVariableButton: boolean;
  contentLength: number;
  characterLimit: number;
  handleCharacterLimitChange: (val: number | null) => void;
  showContentLength: boolean;
  showVariableModal: () => void;
  buttonText: string;
  disableCharacterLimit?: boolean;
  hideCharacterLimit?: boolean;
}

export const LabelRight = (props: LabelRightProps) => {
  const {
    components,
    showVariableButton,
    contentLength,
    showContentLength,
    buttonText,
    showVariableModal,
    characterLimit,
    handleCharacterLimitChange,
    disableCharacterLimit,
    hideCharacterLimit,
  } = props;

  const Container = useMemo(() => components?.labelRight || DefaultLabelRightContainer, [components]);

  const [input, setInput] = useState<string | null>(characterLimit ? characterLimit.toString() : null);

  const [inputFocused, setInputFocused] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = e.target.value.replace(/\D/g, "");
    if (sanitizedValue.length >= 5) return;
    setInput(sanitizedValue);
  };

  const handleCharacterLimitSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputFocused(false);
    let value: string | null = e.target.value;
    if (e.target.value === "") {
      value = null;
    }
    const newValue = value ? parseInt(value) : null;
    if (characterLimit !== newValue) handleCharacterLimitChange(newValue);
    setInput(value);
  };

  const inputElement = useRef<HTMLInputElement>(null);
  const handleOnClick = () => {
    if (disableCharacterLimit) return;
    if (!input) {
      setInput("");
    } else if (inputElement.current) {
      inputElement.current.focus();
    }
  };

  // This is placed in a useEffect instead of in the handleInputChange function because we want it to be called from
  // the handleOnClick method. However we need one render cycle to have completed before focusing the element otherwise
  // the inputElement.current will be null
  useEffect(() => {
    if (input === "" && inputElement.current) {
      inputElement.current.focus();
    }
  }, [input]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      inputElement.current?.blur();
    }
  };

  return (
    <Container className={style.labelContainer}>
      {showVariableButton && (
        <div className={style.insertButton} onMouseDown={showVariableModal}>
          {buttonText}
        </div>
      )}
      {showContentLength && (
        <div
          className={classNames({
            [style.labelRight]: true,
            [style.labelRightFocused]: inputFocused,
            [style.labelRightDisabled]: disableCharacterLimit || hideCharacterLimit,
          })}
          onClick={handleOnClick}
        >
          <div
            className={classNames({
              [style.charCount]: true,
              [style.invalidCharCount]: input && contentLength > parseInt(input),
            })}
          >
            {contentLength}
          </div>
          {!hideCharacterLimit && input !== null && (
            <div className={style.charLimit}>
              <div className={style.charLimitDivider}>/</div>
              <input
                ref={inputElement}
                className={style.charLimitInput}
                disabled={disableCharacterLimit}
                value={input}
                onFocus={() => setInputFocused(true)}
                onChange={handleInputChange}
                onKeyUp={handleKeyPress}
                onBlur={handleCharacterLimitSubmit}
                size={Math.max(1, input?.length || 1)}
              />
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

const DefaultLabelRightContainer: React.FC<{ children: React.ReactNode }> = ({ children, ...props }) => (
  <div {...props}>{children}</div>
);
