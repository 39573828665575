import { Types } from "mongoose";
import { z } from "zod";

export type Populate<Entity extends object, Key extends keyof Entity, PopulatedValue extends object> = Omit<
  Entity,
  Key
> & {
  [key in Key]: PopulatedValue;
};

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export const ZObjectId = z.instanceof(Types.ObjectId);
export type IObjectId = z.infer<typeof ZObjectId>;

export const ZCreatableId = z.union([z.string(), ZObjectId]);
export type ZCreatableId = z.infer<typeof ZCreatableId>;

// Define a recursive mapped type that replaces all ObjectId types with string types
export type FrontendSchema<T> = T extends object
  ? T extends Date
    ? T
    : {
        [K in keyof T]: T[K] extends IObjectId
          ? string
          : T[K] extends IObjectId | null
          ? string | null
          : T[K] extends IObjectId | undefined
          ? string | undefined
          : T[K] extends IObjectId | null | undefined
          ? string | null | undefined
          : FrontendSchema<T[K]>;
      }
  : T;

export type BackendSchema<T> = T extends object
  ? T extends Date
    ? T
    : {
        [K in keyof T]: T[K] extends IObjectId
          ? Types.ObjectId
          : T[K] extends IObjectId | null
          ? Types.ObjectId | null
          : BackendSchema<T[K]>;
      }
  : T;
// Returns an array that ensures that all values are of type T are present.s
export const arrayOfAll =
  <T extends string>() =>
  <U extends T[]>(array: U & ([T] extends [U[number]] ? unknown : `Missing Permission in Array: ${T}`)) =>
    array;
