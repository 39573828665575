import { IFVariable as VariableType } from "@shared/types/Variable";
import { getVariablePlaceholder } from "@shared/utils/variableInterpolation";
import createVariableNode from "../../shared/utils/createVariableNode";
import style from "../components/VariableTextArea/RichTextInput.module.css";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    variable: {
      /**
       * Add a new variable
       */
      addVariable: (
        variableId: string,
        name: string,
        text: string | number | undefined | null,
        variableType: "number" | "string" | "hyperlink" | "list" | "map"
      ) => ReturnType;
    };
  }
}

export const Variable = createVariableNode(
  {
    addCommands() {
      return {
        addVariable:
          (
            variableId: string,
            name: string,
            text: string | number,
            variableType: "number" | "string" | "hyperlink" | "list"
          ) =>
          ({ commands }) =>
            commands.insertContent(
              `<span data-type="${this.name}" 
                data-variable-id="${variableId}"
                data-name="${name}"
                data-text="${text}"
                data-variable-type="${variableType}"
             />`
            ),
      };
    },

    addKeyboardShortcuts() {
      return {
        Backspace: () =>
          this.editor.commands.command(({ tr, state }) => {
            let isVariable = false;
            const { selection } = state;
            const { empty, anchor } = selection;

            if (!empty) {
              return false;
            }

            state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
              if (node.type.name === this.name) {
                isVariable = true;
                tr.insertText("", pos, pos + node.nodeSize);

                return false;
              }
            });

            return isVariable;
          }),
      };
    },

    addAttributes() {
      return {
        variableId: {
          parseHTML: (element) => element.getAttribute("data-variable-id"),
          renderHTML: (attributes) => {
            return {
              "data-variable-id": attributes.variableId,
            };
          },
        },
        name: {
          parseHTML: (element) => element.getAttribute("data-name"),
          renderHTML: (attributes) => {
            return {
              "data-name": attributes.name,
            };
          },
        },
        text: {
          parseHTML: (element) => element.getAttribute("data-text"),
          renderHTML: (attributes) => {
            return {
              "data-text": attributes.text,
            };
          },
        },
        variableType: {
          parseHTML: (element) => element.getAttribute("data-variable-type"),
          renderHTML: (attributes) => {
            return {
              "data-variable-type": attributes.variableType,
            };
          },
        },
      };
    },
  },
  style.variableSpan
);

export const variableTextToHtmlVariable = (text: string, variables: VariableType[]) => {
  if (!text) {
    return "<p></p>";
  }

  // Find all variables by searching for {{WORD}}
  const inTextVariables = text.match(/{{[^{\{]+(?=}\})}}/g);

  if (!inTextVariables) return `<p>${text}</p>`;

  let result = text;
  for (let inTextVariable of inTextVariables) {
    // Remove {{}}
    const inTextVariableName = inTextVariable.match(/[^{\{]+(?=}\})/g)?.pop();

    // Check to see if it is a valid variable
    const matchingVariableIndex = variables.findIndex((variable) => variable.name === inTextVariableName);

    if (matchingVariableIndex !== -1) {
      const matchedVariable = variables[matchingVariableIndex];
      const variableText = getVariablePlaceholder(matchedVariable);
      result = result.replace(
        `${inTextVariable}`,
        `<span data-type="variable" data-variable-id="${matchedVariable._id}" data-name="${matchedVariable.name}" data-text="${variableText}" data-type="${matchedVariable.type}" contenteditable="false">${matchedVariable.name}</span>`
      );
    }
  }
  return `<p>${result}</p>`;
};
