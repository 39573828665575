import { atom, useAtomValue } from "jotai";
import { useHistory } from "react-router-dom";

export const searchParamsAtom = atom<URLSearchParams>(new URLSearchParams());

/**
 * These selection state atoms are derived from the url search params
 * and are read only. They cannot and should not be updated directly.
 */
export const selectedTextItemIdsAtom = atom<string[]>((get) => {
  const params = get(searchParamsAtom);

  const textItemIdsParam = params.get("textItemIds");

  if (!textItemIdsParam) {
    return [];
  }

  return textItemIdsParam.split(",");
});

export const selectedBlockIdsAtom = atom<string[]>((get) => {
  const params = get(searchParamsAtom);

  const blockIdsParam = params.get("blockIds");

  if (!blockIdsParam) {
    return [];
  }

  return blockIdsParam.split(",");
});

const SidebarPanelStates = ["EDIT", "ACTIVITY", null] as const;
type SidebarPanelStates = (typeof SidebarPanelStates)[number];

export const selectedSidebarPanelAtom = atom<SidebarPanelStates>((get) => {
  const params = get(searchParamsAtom);

  const paramValue = params.get("sidebarPanel");

  if (paramValue && SidebarPanelStates.includes(paramValue as SidebarPanelStates)) {
    return paramValue as SidebarPanelStates;
  }

  return null;
});

/**
 * All URL updates around selection state should be done through this service layer hook.
 */
export function useSelectionStateUpdate() {
  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);
  const selectedBlockIds = useAtomValue(selectedBlockIdsAtom);
  const history = useHistory();

  function addTextItemIds(textItemIds: string[]) {
    const searchParams = new URLSearchParams(location.search);

    const newSelectedTextItemIds = selectedTextItemIds.concat(textItemIds);

    searchParams.set("textItemIds", newSelectedTextItemIds.join(","));

    history.push({
      search: searchParams.toString(),
    });
  }

  function removeTextItemIds(textItemIds: string[]) {
    const searchParams = new URLSearchParams(location.search);

    const newSelectedTextItemIds = selectedTextItemIds.filter((id) => !textItemIds.includes(id));

    if (newSelectedTextItemIds.length === 0) {
      searchParams.delete("textItemIds");
    } else {
      searchParams.set("textItemIds", newSelectedTextItemIds.join(","));
    }

    history.push({
      search: searchParams.toString(),
    });
  }

  function addBlockIds(blockIds: string[]) {
    const searchParams = new URLSearchParams(location.search);

    const newSelectedBlockIds = selectedBlockIds.concat(blockIds);

    searchParams.set("blockIds", newSelectedBlockIds.join(","));

    history.push({
      search: searchParams.toString(),
    });
  }

  function removeBlockIds(blockIds: string[]) {
    const searchParams = new URLSearchParams(location.search);

    const newSelectedBlockIds = selectedBlockIds.filter((id) => !blockIds.includes(id));

    if (newSelectedBlockIds.length === 0) {
      searchParams.delete("blockIds");
    } else {
      searchParams.set("blockIds", newSelectedBlockIds.join(","));
    }

    history.push({
      search: searchParams.toString(),
    });
  }

  function updateSidebarPanel(sidebarPanel: SidebarPanelStates) {
    const searchParams = new URLSearchParams(location.search);

    if (sidebarPanel) {
      searchParams.set("sidebarPanel", sidebarPanel);
    } else {
      searchParams.delete("sidebarPanel");
    }

    history.push({
      search: searchParams.toString(),
    });
  }

  return {
    addTextItemIds,
    removeTextItemIds,
    addBlockIds,
    removeBlockIds,
    updateSidebarPanel,
  };
}
