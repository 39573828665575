import { mergeAttributes, Node } from "@tiptap/core";

type NodeCreateParams = Parameters<typeof Node.create>;

// This function allows the backend to use the VariableNode when
// generating HTML from Tiptap data. As a result it doesn't include
// add commands, keyboard shorcuts, and style
function createVariableNode(parameters: NodeCreateParams[0], className: string) {
  return Node.create({
    name: "variable",

    addOptions() {
      return {
        HTMLAttributes: {},
      };
    },

    group: "inline",

    inline: true,

    selectable: false,

    atom: true,

    addAttributes() {
      return {
        variableId: {
          renderHTML: (attributes) => {
            return {
              "data-variable-id": attributes.variableId,
            };
          },
        },
        name: {
          renderHTML: (attributes) => {
            return {
              "data-name": attributes.name,
            };
          },
        },
        text: {
          renderHTML: (attributes) => {
            return {
              "data-text": attributes.text,
            };
          },
        },
        variableType: {
          renderHTML: (attributes) => {
            return {
              "data-variable-type": attributes.variableType,
            };
          },
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: `span[data-type="${this.name}"]`,
        },
      ];
    },

    renderHTML({ node, HTMLAttributes }) {
      return [
        "span",
        mergeAttributes(
          {
            "data-type": this.name,
            class: className,
          },
          HTMLAttributes
        ),
        node.attrs.text,
      ];
    },

    // When copying text
    renderText({ node }) {
      return `{{${node.attrs.name}}}`;
    },

    ...parameters,
  });
}

export default createVariableNode;
