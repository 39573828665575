import { useWorkspace } from "@/store/workspaceContext";
import * as Sentry from "@sentry/react";
import { IFFeatureFlags, IFProject } from "@shared/types/Project";
import { FullGroup, LinkedFullProject, isLinkedGroup, isTextItemConnectedToFigma } from "@shared/types/http/project";
import { Editor } from "@tiptap/core";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IFActualChange } from "../../../../shared/types/ActualChange";
import { IFCommentThread } from "../../../../shared/types/CommentThread";
import { useScroll } from "../../../hooks/useScroll";
import useSearchState, { SearchState } from "../../../hooks/useSearchState";
import useSegment from "../../../hooks/useSegment";
import http, { API } from "../../../http";
import { pollingBackgroundJobRequest } from "../../../http/lib/clientHelpers";
import { UnsavedChangesContext } from "../../../store/unsavedChangesContext";
import { WebappPermissionProvider as UserPermissionProvider } from "../../../store/webappPermissionContext";
import { BLOCK_NAME } from "../components/GroupDraft/editor/nodes/DraftBlockNode";
import { ProjectSummaryState, useProjectSummaryState } from "../components/ProjectSummarySection";
import { getCategorizedGroups } from "./getCategorizedGroups";
import { GroupReducerAction } from "./groupStateActions";
import { Group, TextItem, isGroupLinkable, isGroupLinked, isGroupUnlinkable } from "./types";
import useGroupRenderState, { GroupRenderState } from "./useGroupRenderState";
import useGroupState, { GroupState } from "./useGroupState";
import usePageState, { DRAFTED_GROUPS_PAGE, PageSelected, PageState } from "./usePageState";
import useResyncState, { ResyncState } from "./useResyncState";
import useSetupSuggestionsState, { SetupSuggestionsState } from "./useSetupSuggestionsState";
import useUnsavedGroupChangesState, { UnsavedGroupChangesState } from "./useUnsavedChangesState";

export const PAGE_FRAME_LIMIT = 10;

export interface Variant {
  name: string;
  id: string;
}
export interface ProjectVariantsState {
  docVariants: Record<string, string>;
  frameVariants: Record<string, { id: string; name: string }[]>;
  workspaceVariants: Record<string, string>;
}

type BranchProjectInfo = {
  _id: string;
  name: string;
  isLocked: boolean;
  figmaURL: string;
};
type ProjectBranchInfo =
  | {
      isBranch: boolean;
      mainProjectInfo: BranchProjectInfo | null;
      branchProjectInfo: BranchProjectInfo | null;
      canMerge: boolean;
    }
  | {
      isBranch: boolean;
      mainProjectInfo: BranchProjectInfo | null;
      branchProjectInfo: BranchProjectInfo | null;
      canMerge: boolean;
      mergedBranches: { _id: string; name: string }[];
    };

type CreateTextItemPanelStatus = { show: boolean; groupId: string | null };
// TODO: move this somewhere more shareable
interface ProjectChangeItem extends IFActualChange {}

// TODO: move this somewhere more shareable
interface TextItemChangeItem {}

interface FramePreviewsMap {
  [figmaFrameId: string]: {
    previews: { fullsize: string };
    previewsLastUpdatedAt: Date;
  };
}

interface DraftGroupFocusHandlers {
  [draftGroupId: string]: () => void;
}

interface DraftGroupEditorReferences {
  [draftGroupId: string]: Editor;
}

type QuickReplyCommentState =
  | { enabled: false }
  | {
      enabled: true;
      initialThreadId: string;
      goBackState?: Record<string, string>;
    };

type ProjectFolder = {
  invite_only: boolean;
  name: string;
  _id: string;
};

type ProjectState = {
  activityFilter: React.StatePair<string | null>;
  allCommentHistoryFetched: React.StatePair<boolean>;
  allDocHistoryFetched: React.StatePair<boolean>;
  branchId?: string;
  fetchCommentHistory: () => Promise<IFCommentThread[] | null>;
  formatCommentThreads: (items: IFActualChange[], comments: IFCommentThread[]) => IFActualChange[];
  commentHistory: React.StatePair<ProjectChangeItem[]>;
  commentHistoryIndex: React.StatePair<number>;
  commentHistoryLoading: React.StatePair<boolean>;
  compBeingEdited: React.StatePair<TextItem | null>;
  compHistory: React.StatePair<TextItemChangeItem[]>;
  compHistoryLoading: React.StatePair<boolean>;
  createTextItemPanelStatus: React.StatePair<CreateTextItemPanelStatus>;
  // API Ids
  displayApiIds: React.StatePair<boolean>;
  doc: React.StatePair<IFProject | null>;
  docComponents: React.StatePair<{ _id: string }[]>;
  docHistory: React.StatePair<ProjectChangeItem[]>;
  docHistoryIndex: React.StatePair<number>;
  docHistoryLoading: React.StatePair<boolean>;
  fetchNewHistory: () => Promise<void>;
  fetchProjectBranchInfo: () => Promise<void>;
  findTextItemInProject: (textItemId: string) => TextItem | null;
  findTextItemsInProject: (textItemIds: string[]) => TextItem[];
  folder: React.StatePair<ProjectFolder | null>;
  forceMultiSelectOn: React.StatePair<boolean>;
  framePage: React.StatePair<number>;
  framePreviewsMap: FramePreviewsMap;
  getActiveVariantIndexForGroup: (groupId: string) => number;
  getDocVariants: () => Promise<void>;
  groupIndicesByGroupId: Map<string, number>;
  groupPreviewStateById: React.StatePair<Map<string, "TEXT" | "DESIGN">>;
  groupRenderState: GroupRenderState;
  groupState: [GroupState, React.Dispatch<GroupReducerAction>];
  groupTypeCounts: {
    unlinkable: number;
    linkable: number;
    linked: number;
  };
  handleScrollToTextItem: (textItemId: string) => void;
  highlightDraftTextItem: (textItemId: string) => void;
  isProjectFlagEnabled: (flagName: string) => Boolean;
  multiSelectOn: boolean;
  multiSelectedComps: React.StatePair<TextItem[]>;
  multiSelectedIds: React.StatePair<string[]>;
  multiSelectedVariants: React.StatePair<Variant[] | null>;
  onDraftEditorDestroy: (groupId: string) => void;
  previewsJobId: React.StatePair<string | null>;
  projectBranchInfo: React.StatePair<ProjectBranchInfo | null>;
  projectId: string;
  projectSummary: readonly [ProjectSummaryState, () => void];
  /**
   * Determines whether or not the "Quick reply" panel with comment
   * pagination is visible in the edit panel of a selected text item.
   */
  quickReplyCommentState: React.StatePair<QuickReplyCommentState>;
  registerEditorReference: (groupId: string, editorRef: Editor) => void;
  registerFocusFunction: (groupId: string, focusHandler: () => void) => void;
  resetCommentHistory: React.StatePair<boolean>;
  resetDocHistory: React.StatePair<boolean>;
  richTextModalOpen: React.StatePair<boolean>;
  scrollToId: React.StatePair<string | null>;
  search: SearchState;
  selectedComp: React.StatePair<TextItem | null>;
  selectedDraftGroupId: React.StatePair<string | null>;
  selectedGroupId: React.StatePair<string | null>;
  selectedId: string | null;
  setActiveVariantIndexForGroup: (groupId: string, index: number) => void;
  setActiveVariantOnGroup: (groupId: string, variantId: string) => void;
  setFramePreviewsMap: React.Dispatch<React.SetStateAction<FramePreviewsMap>>;
  setPageByTextItemId: (textItemId: string) => void;
  setVariants: React.Dispatch<React.SetStateAction<ProjectVariantsState>>;
  showMergeBranchModal: React.StatePair<boolean>;
  showToast: React.StatePair<boolean>;
  suggestedCompId: React.StatePair<string | null>;
  unselectAll: () => void;
  updateActiveVariantIndexForAllGroups: (variantId: string) => void;
  updateDocHistory: React.StatePair<boolean>;
  updateFramePreviewsMap: (project: LinkedFullProject) => void;
  updateProjectName: (name: string) => void;
  updateProjectFeatureFlags: (featureFlags: IFFeatureFlags) => void;
  variants: ProjectVariantsState;
  variantsLoading: boolean;
} & PageState &
  UnsavedGroupChangesState &
  SetupSuggestionsState &
  ResyncState;

export const ProjectContext = createContext({} as ProjectState);
export const useProjectContext = () => useContext(ProjectContext);

export const ProjectProvider = ({
  children,
  projectState,
}: {
  children: React.ReactNode;
  projectState: ProjectState;
}) => {
  const folderId = projectState.folder[0]?._id;

  return (
    <ProjectContext.Provider value={projectState}>
      <UserPermissionProvider resourceId={folderId} resourceType={"project_folder"}>
        {children}
      </UserPermissionProvider>
    </ProjectContext.Provider>
  );
};

/**
 * Whenever the selected draft group changes, focus the content of the newly selected
 * group.
 */
const useDraftGroupFocusManager = (props: {
  handlers: DraftGroupFocusHandlers;
  selectedDraftGroupId: ProjectState["selectedDraftGroupId"];
}) => {
  const { handlers } = props;
  const [selectedDraftGroupId] = props.selectedDraftGroupId;

  useEffect(() => {
    if (!selectedDraftGroupId) {
      return;
    }

    const focusSelectedGroup = handlers[selectedDraftGroupId];
    if (!focusSelectedGroup) {
      return;
    }

    focusSelectedGroup();
  }, [selectedDraftGroupId, handlers]);
};

interface ProjectStateParams {
  initialGroups?: Group[];
}

export const initializeProjectState = (params?: ProjectStateParams): ProjectState => {
  const segment = useSegment();
  const { id: projectId, branchId } = useParams<{
    id: string | undefined;
    branchId: string | undefined;
  }>();
  const {
    canSaveEdits: [, setCanSaveEdits],
  } = useContext(UnsavedChangesContext);

  if (!projectId) {
    throw new Error(`Project state can't be initialized without an id`);
  }

  const history = useHistory();
  const { workspaceInfo } = useWorkspace();
  const workspaceId = workspaceInfo?._id?.toString() || "";

  const doc = useState<IFProject | null>(null);
  const folder = useState<ProjectFolder | null>(null);
  const [framePreviewsMap, setFramePreviewsMap] = useState<FramePreviewsMap>({});
  /*
   * A flat array of all the components in the current doc, used
   * when calculating ranges of components during shift clicks and
   * to populate the component edit panel with the selected component's details.
   */
  const docComponents = useState<{ _id: string }[]>([]);

  const compHistory = useState<TextItemChangeItem[]>([]);
  const compHistoryLoading = useState(false);

  const displayApiIds = useState(false);

  const suggestedCompId = useState<string | null>(null);
  const previewsJobId = useState<string | null>(null);

  const updateDocHistory = useState(false);
  const docHistory = useState<ProjectChangeItem[]>([]);
  const docHistoryLoading = useState(true);
  const docHistoryIndex = useState(0);
  const allDocHistoryFetched = useState(false);
  const resetDocHistory = useState(false);

  const commentHistory = useState<ProjectChangeItem[]>([]);
  const commentHistoryLoading = useState(true);
  const commentHistoryIndex = useState(0);
  const allCommentHistoryFetched = useState(false);
  const resetCommentHistory = useState(false);
  const quickReplyCommentState = useState<QuickReplyCommentState>({
    enabled: false,
  });

  const projectBranchInfo = useState<ProjectBranchInfo | null>(null);
  const projectSummary = useProjectSummaryState(projectId);

  const createTextItemPanelStatus = useState<CreateTextItemPanelStatus>({
    show: false,
    groupId: null,
  });

  const [variants, setVariants] = useState<ProjectVariantsState>({
    docVariants: {},
    frameVariants: {},
    workspaceVariants: {},
  });
  const [variantsLoading, setVariantsLoading] = useState(true);

  const multiSelectedVariants = useState<Variant[] | null>(null);

  /*
   * This is set to true if the user manually clicks the multi-select
   * button or clicks on a component while a modifier key (shift or meta)
   * is pressed.
   *
   * This is set to false if the user clicks on a component without any
   * modifier keys pressed.
   */
  const forceMultiSelectOn = useState(false);
  const showToast = useState(false);
  const groupPreviewStateById = useState(new Map<string, "TEXT" | "DESIGN">());

  const selectedDraftGroupId = useState<string | null>(null);
  const [draftGroupFocusHandlers, setDraftGroupFocusHandlers] = useState<DraftGroupFocusHandlers>({});

  const richTextModalOpen = useState<boolean>(false);

  const registerFocusFunction = useCallback(
    (groupId: string, focusHandler: () => void) => {
      setDraftGroupFocusHandlers((handlers) => ({
        ...handlers,
        [groupId]: focusHandler,
      }));
    },
    [setDraftGroupFocusHandlers]
  );

  useDraftGroupFocusManager({
    handlers: draftGroupFocusHandlers,
    selectedDraftGroupId,
  });

  const draftGroupEditorReferences = useRef<DraftGroupEditorReferences>({});
  const registerEditorReference = useCallback((groupId: string, editorRef: Editor) => {
    draftGroupEditorReferences.current[groupId] = editorRef;
  }, []);

  const onDraftEditorDestroy = useCallback(
    (groupId: string) => {
      setDraftGroupFocusHandlers((handlers) => {
        const h = { ...handlers };
        delete h[groupId];
        return h;
      });

      delete draftGroupEditorReferences.current[groupId];
    },
    [setDraftGroupFocusHandlers]
  );

  const [selectedComp, setSelectedComp] = useState<TextItem | null>(null);
  const selectedId = selectedComp?._id || null;
  const selectedGroupId = useState<string | null>(null);

  const activityFilter = useState("Activity");

  /*
   * I'm not sure why we need these two separate pieces of
   * state to track selections - would be good to refactor this
   * into a single array at some point.
   */
  const multiSelectedComps = useState<TextItem[]>([]);
  const multiSelectedIds = useState<string[]>([]);
  const [multiSelectedIdsValue] = multiSelectedIds;

  const multiSelectOn = useMemo(() => multiSelectedIds[0].length > 1, [multiSelectedIds]);

  const compBeingEdited = useState<TextItem | null>(null);

  // Unfocus selected editor whenever the selectedId is cleared.
  // This is particularly useful for covering cases of deselection
  // that TipTap/ProseMirror don't inherently detect, like hitting
  // escape.
  useEffect(() => {
    // Debouncing is required since `selectedId` and `multiSelectedIds` are
    // updated separately in state - often, both will be updated in immediate
    // sequence, causing this effect to trigger twice in rapid succession.
    //
    // For example, without this debounce, multi-selection across text items
    // in a draft group does not work because this effect immediately runs
    // when `selectedId` is set to null but BEFORE `multiSelectedIds` is given
    // a value, causing the group to be blurred.
    const debounceInterval = 100;
    const debouncedDeselection = setTimeout(() => {
      if (selectedId || multiSelectedIdsValue.length) {
        return;
      }

      const focusedEditor: Editor | undefined = Object.values(draftGroupEditorReferences.current).find((editor) =>
        editor.view.hasFocus()
      );
      if (!focusedEditor) {
        return;
      }

      focusedEditor.commands.blur();

      // Reset the selection of the editor after being blurred to avoid
      // weird scenarios were an editor isn't focused but it still has
      // text highlighted inside of it.
      //
      // A small delay is necessary to give the editor time to be fully
      // blurred prior to changing the selection; if the editor still
      // technically has focus when the text selection is changed,
      // the first text item in the group will end up selected.
      setTimeout(() => focusedEditor.commands.setTextSelection(0), 10);
    }, debounceInterval);

    return () => clearTimeout(debouncedDeselection);
  }, [selectedId, multiSelectedIdsValue]);

  const updateProjectName = (name: string) => {
    const [_, setProject] = doc;
    setProject((project) => {
      if (!project) {
        throw new Error("Can't update project name while the project hasn't loaded");
      }

      return { ...project, doc_name: name };
    });
  };

  const updateProjectFeatureFlags = (featureFlags: Partial<IFFeatureFlags>) => {
    const [_, setProject] = doc;
    setProject((project) => {
      if (!project) {
        throw new Error("Can't update project name while the project hasn't loaded");
      }

      return { ...project, feature_flags: { ...project.feature_flags, ...featureFlags } };
    });
  };

  const queryParams = new URLSearchParams(window.location.search);
  const search = useSearchState({ assignee: queryParams.get("assignee") || undefined });
  const page = usePageState();

  const groupState = useGroupState(params?.initialGroups);
  const [{ groups }, groupStateDispatch] = groupState;

  const groupsCategorized = useMemo(() => getCategorizedGroups(groups), [groups]);

  // MARK: - Frame Variant Logic

  /**
   * This map keeps a record of the active "variant index" for each group in the project. This is
   * the index of the variant with respect to the list of variants on a given group, as listed in
   * the `frameVariants` object.
   *
   * Everywhere in the project where the "selected variant" is important flows down from this logic.
   * There are a couple of helper methods to set this below, but they're all variations on "set the
   * active variant index for a group given the groupID/groupIndex/variantId/variantIndex".
   *
   * Final note: a variant index == -1 will always result in the Base tab being selected.
   */

  const [activeVariantIndexByGroupId, setActiveVariantIndexByGroupId] = useState<Map<string, number>>(new Map());

  const setActiveVariantIndexForGroup = (groupId: string, index: number) => {
    setActiveVariantIndexByGroupId((prev) => new Map(prev).set(groupId, index));
  };

  const getActiveVariantIndexForGroup = (groupId: string) => {
    const value = activeVariantIndexByGroupId.get(groupId);
    if (typeof value !== "number") return -1;
    return value;
  };

  // Figure out the variant index of a given variantId on a given group
  const getVariantIndexOnGroup = (groupId: string, variantId: string | null) => {
    if (!variantId) return -1;
    const groupVariants = variants.frameVariants[groupId];
    if (!groupVariants) return -1;
    return groupVariants.findIndex((v) => v.id === variantId);
  };

  const setActiveVariantOnGroup = (groupId: string, variantId: string | null) => {
    const index = getVariantIndexOnGroup(groupId, variantId);
    setActiveVariantIndexForGroup(groupId, index);
  };

  const updateActiveVariantIndexForAllGroups = (variantId: string | null) => {
    const map = new Map<string, number>();

    groups.forEach((group) => {
      const groupId = group._id;
      // if the variant filter was cleared, then set the active variant index to -1
      // for all groups
      if (!variantId) {
        map.set(groupId, -1);
      }

      // if we're not tracking a list of variants for a given group, skip
      // trying to set the tab index
      const groupVariants = variants.frameVariants[groupId];
      if (!groupVariants?.length) return;

      // if we can't find the variant in a given group's list of variants,
      // skip trying to set the tab indx
      const variantIndex = groupVariants.findIndex((v) => v.id === variantId);
      if (variantIndex === -1) return;

      map.set(groupId, variantIndex);
    });

    // hack: if we're setting an index for groups that haven't rendered
    // yet, there could be a race condition with the render such that the index
    // for a given group gets set to 0. this ensures that this render call is
    // executed last and wins
    setTimeout(() => setActiveVariantIndexByGroupId(map));
  };

  const findTextItemsInProject = useCallback(
    (textItemIds: string[]): TextItem[] => {
      const textItemsToFindSet = textItemIds.reduce((set, id) => set.add(id), new Set<string>());

      const textItemsFound: TextItem[] = [];
      for (const group of groups) {
        for (const textItem of group.comps) {
          if (textItemsToFindSet.has(textItem._id.toString())) {
            textItemsFound.push(textItem);
          }
        }
        for (const block of group.blocks) {
          // For some reason it is possible for a block to be null
          // https://making-ditto.slack.com/archives/C0328T320TE/p1683814961908279
          if (!block) continue;

          for (const textItem of block.comps) {
            if (textItemsToFindSet.has(textItem._id.toString())) {
              textItemsFound.push(textItem);
            }
          }
        }
      }

      return textItemsFound;
    },
    [groups]
  );

  const findTextItemInProject = useCallback(
    (textItemId: string): TextItem | null => {
      const [textItem] = findTextItemsInProject([textItemId]);
      return textItem || null;
    },
    [findTextItemsInProject]
  );

  const setPageByTextItemId = (textItemId: string) => {
    const [, setSelectedPage] = page.selectedPage;
    const [, setFramePage] = framePage;

    const group = groups.find(
      (group) =>
        group.comps.some(({ _id }) => _id.toString() === textItemId) ||
        group.blocks.some(({ comps }) => comps.some(({ _id }) => _id.toString() === textItemId))
    );
    // textItemId from ActualChange object could have been deleted in a resync
    if (!group) {
      return;
    }
    let textItemPage: PageSelected = DRAFTED_GROUPS_PAGE;
    if (isGroupLinked(group)) {
      const groupPage = doc[0]?.integrations.figma.selected_pages?.find(
        (page) => page.figma_id === group.integrations.figma.page_id
      );
      if (groupPage) {
        textItemPage = {
          id: groupPage.figma_id,
          name: groupPage.name,
        };
      }
    }
    setSelectedPage(textItemPage);

    const groupOnPageFilter = isGroupLinked(group)
      ? (currentGroup) => currentGroup.integrations.figma.page_id === group.integrations.figma.page_id
      : (currentGroup) => !isGroupLinked(currentGroup);

    const groupsOnPage = groups
      .filter(groupOnPageFilter)
      .sort((a, b) => (a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1));

    const groupIndex = groupsOnPage.findIndex((currentGroup) => currentGroup._id.toString() === group._id.toString());
    const newFramePage = Math.floor(groupIndex / PAGE_FRAME_LIMIT);

    setFramePage(newFramePage);
  };

  const fetchCommentHistory = async () => {
    const [docInState] = doc;

    try {
      const { url } = API.doc.get.comments;
      const { data } = await http.get(url(docInState?._id));
      return data;
    } catch (e) {
      console.error("Error fetching document comment history", e.message);
      Sentry.captureException(e, {
        extra: { doc_ID: docInState?._id },
      });
      return null;
    }
  };

  // replaces 'post-comment' & 'post-reply' with the actual commment thread
  const formatCommentThreads = (items: IFActualChange[], comments: IFCommentThread[]) => {
    try {
      const commentThreadsMap = {};
      comments.map((comment) => (commentThreadsMap[comment._id] = { comment, seenBefore: false }));
      const newHistory: IFActualChange[] = [];
      items.forEach((item) => {
        if (item.entry_type === "post-comment" || item.entry_type === "post-reply") {
          const itemToPush = { ...item, comment_thread: null };
          if (
            item.comment_thread_id &&
            commentThreadsMap[item.comment_thread_id] &&
            !commentThreadsMap[item.comment_thread_id].seenBefore
          ) {
            itemToPush.entry_type = "inline-comment";
            itemToPush.comment_thread = commentThreadsMap[item.comment_thread_id].comment;
            commentThreadsMap[item.comment_thread_id].seenBefore = true;
            newHistory.push(itemToPush);
          }
        } else {
          newHistory.push(item);
        }
      });
      return newHistory;
    } catch (error) {
      console.error("Error formatting comment threads:", error);
      return [];
    }
  };

  const currentChangeItemIdSet = useMemo(() => {
    const changeItemIdSet = new Set<string>();
    docHistory[0].forEach((changeItem: any) => changeItemIdSet.add(changeItem._id.toString()));
    return changeItemIdSet;
  }, [docHistory[0]]);

  // This function calls the backend to get the newest changes to the project, and if there are any
  // new changes, adds them to state. It should *not* totally refresh all history state.
  const fetchNewHistory = async () => {
    const commentThreads = await fetchCommentHistory();
    if (!commentThreads) return;
    const commentThreadsUnresolved = commentThreads.filter((thread) => !thread.is_resolved);

    const [_docHistory, _setDocHistory] = docHistory;

    try {
      const { url } = API.changes.get.docPage;
      const {
        data: { info },
      } = await http.get(url(doc[0]?._id ?? "", 0, 20)); // fetch the first 20 items -- we don't care about current
      const newHistoryItems = formatCommentThreads(info, commentThreadsUnresolved);
      if (!newHistoryItems) return;

      let changesToAdd = newHistoryItems.filter((change) => !currentChangeItemIdSet.has(change._id.toString()));

      newHistoryItems.forEach((change) => {
        if (
          currentChangeItemIdSet.has(change._id.toString()) &&
          ["component-auto-attached", "frame-auto-attached"].includes(change.entry_type)
        ) {
          const index = _docHistory.findIndex((item) => item._id.toString() === change._id.toString());
          if (index > -1) {
            _docHistory[index] = change;
          }
        }
      });

      // add new history items to state
      _setDocHistory([...changesToAdd, ..._docHistory]);
    } catch (e) {
      console.error("Failed to fetch new history", e);
    }
  };

  const unsavedGroupChanges = useUnsavedGroupChangesState();

  useEffect(
    function validateBranchProjectUrl() {
      if (!doc[0]) return;
      // @ts-ignore
      const projectBranchId = doc[0]?.integrations?.figma?.branch_id;

      // validate current path /projects/:projectId/branch/:branchId is valid
      const notValidBranchProject = branchId && !projectBranchId;
      if (notValidBranchProject) {
        history.replace(`/projects/${projectId}`);
        return;
      }
      // check if project is linked to figma branch not using the branchId in the url
      // redirect to /projects/:projectId/branch/:branchId
      const incorrectBranchId = branchId && projectBranchId && branchId !== projectBranchId;
      const isBranchProjectMissingBranchParam = !branchId && projectBranchId;
      if (incorrectBranchId || isBranchProjectMissingBranchParam) {
        history.replace(`/projects/${projectId}/branch/${projectBranchId}`);
        return;
      }
    },
    [branchId, doc]
  );

  useEffect(() => {
    const isLoading = groupState[0].fetching;
    const hasDraftGroups = groupsCategorized.groupsUnlinked.length;

    if (isLoading || hasDraftGroups) {
      return;
    }

    groupStateDispatch({
      type: "ADD_DEFAULT_UNLINKED_GROUP",
      projectId,
      workspaceId,
    });
  }, [projectId, workspaceId, groupsCategorized, groupStateDispatch, groupState]);

  /*
   * The index of the frame pagination
   */
  const framePage = useState(0);

  const groupRenderState = useGroupRenderState({
    search,
    page,
    variants,
    groupsCategorized,
    project: doc[0],
  });

  const setupSuggestionsState = useSetupSuggestionsState(groupRenderState.groups.searchFilteredGroups);

  const extractGroupTextPositions = (group: FullGroup) => {
    const frameTextPositionMap = {};

    (group?.blocks || []).forEach((block) => {
      (block?.comps || []).forEach((comp) => {
        if (isTextItemConnectedToFigma(comp)) {
          frameTextPositionMap[comp.figma_node_ID] = comp?.integrations?.figma?.position;
        }
      });
    });

    group?.comps.forEach((comp) => {
      if (isTextItemConnectedToFigma(comp)) {
        frameTextPositionMap[comp.figma_node_ID] = comp?.integrations?.figma?.position;
      }
    });
    return frameTextPositionMap;
  };

  const updateFramePreviewsMap = (project: LinkedFullProject) => {
    try {
      const { groups } = project;

      const previewsLastUpdatedAt =
        (project.integrations.figma.previews_updated_at ?? Date.now()) >
        (project.integrations.figma.resynced_at ?? Date.now())
          ? project.integrations.figma.previews_updated_at
          : project.integrations.figma.resynced_at;

      const previewsMap = groups.reduce((acc, group) => {
        if (!isLinkedGroup(group)) {
          return acc;
        }
        group.integrations.figma;
        const { previews, frame_id, position } = group.integrations.figma;

        const frameTextDimensions = extractGroupTextPositions(group);

        return {
          ...acc,
          [frame_id]: {
            previews,
            previewsLastUpdatedAt,
            frameDimensions: position,
            textDimensions: frameTextDimensions,
          },
        };
      }, {});
      setFramePreviewsMap((prev) => ({ ...prev, ...previewsMap }));
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  const getDocVariants = async () => {
    try {
      const { url } = API.variant.get.variantId;
      const { data: responseVariants } = await http.get(url(projectId));
      setVariants(responseVariants);
      setVariantsLoading(false);
    } catch (error) {
      console.error("Error fetching variants", error);
    }
  };

  const showMergeBranchModal = useState(false);

  const fetchProjectBranchInfo = async () => {
    try {
      const data = await pollingBackgroundJobRequest<ProjectBranchInfo>({
        url: "/jobs/figmaGetBranchInfo",
        requestBody: {
          projectId,
          validateFigma: true,
        },
      });
      projectBranchInfo[1](data);
      const isMergedBranch = doc[0] && doc[0].is_locked && (doc[0].integrations.figma as any).branch_id;
      if (data.canMerge && !isMergedBranch) {
        showMergeBranchModal[1](true);
      }
    } catch (error) {
      console.error("Error doing fetchProjectBranchInfo", error);
    }
  };

  const unselectAll = () => {
    quickReplyCommentState[1]({ enabled: false });
    setSelectedComp(null);
    createTextItemPanelStatus[1]({ show: false, groupId: null });
    forceMultiSelectOn[1](false);
    multiSelectedIds[1]([]);
    multiSelectedComps[1]([]);
    multiSelectedVariants[1]([]);
    setCanSaveEdits(false);
    selectedDraftGroupId[1](null);
    activityFilter[1]("Activity");
  };

  const resyncState = useResyncState({
    projectId,
    doc,
    setSuggestedCompId: suggestedCompId[1],
    groupStateDispatch,
    setUpdateDocHistory: updateDocHistory[1],
    figmaFileId: (doc[0]?.integrations?.figma as any)?.file_id,
    selectedComp,
    multiSelectedIds: multiSelectedIds[0],
    multiSelectOn,
    setPreviewsJobId: previewsJobId[1],
    updateFramePreviewsMap,
    getDocVariants,
    fetchProjectBranchInfo,
    unselectAll,
    showToast: showToast[0],
  });

  const groupIndicesByGroupId = useMemo(
    () => groups.reduce((map, group, index) => map.set(group._id.toString(), index), new Map<string, number>()),
    [groups]
  );
  const groupTypeCounts = useMemo(() => {
    let unlinkable = 0;
    let linkable = 0;
    let linked = 0;

    groups.forEach((group) => {
      if (isGroupUnlinkable(group)) return unlinkable++;
      if (isGroupLinkable(group)) return linkable++;
      if (isGroupLinked(group)) return linked++;

      throw new Error("Invalid group type " + JSON.stringify((group as Group).integrations));
    });

    return { unlinkable, linkable, linked };
  }, [groups]);

  /**
   * Given a text item id:
   * 1. finds the associated text item's group in the group state
   * 2. waits for that group's TipTap editor reference to initialize (in the case
   * that the highlight was triggered from a different page)
   * 3. computes the appropriate selection range for the text item
   * 4. focuses the editor and highlights the text item inside of it
   */
  const highlightDraftTextItem = useCallback(
    async (textItemId: string) => {
      const group = groupState[0].groups.find((group) =>
        group.comps.some((textItem) => textItem._id.toString() === textItemId)
      );
      if (!group || isGroupLinked(group)) {
        return;
      }

      const groupId = group._id.toString();

      /**
       * If we try to highlight a text item that is on another page,
       * we'll encounter an issue because the TipTap editor of that
       * text item will not yet be initialized by the time that this
       * code runs.
       *
       * To counteract this, we recursively check to see if the editor
       * reference has been defined until either it has been found
       * or until we exceed a reasonable number of attempts, the latter indicating
       * that the editor will likely never load.
       */
      const FIND_DRAFT_EDITOR_DELAY_MS = 20;
      const FIND_DRAFT_EDITOR_MAX_ATTEMPTS = 10;
      const tryToFindDraftEditor = async (attempt = 0) => {
        const editorRef = draftGroupEditorReferences.current[groupId];
        if (editorRef) {
          return editorRef;
        }

        if (attempt < FIND_DRAFT_EDITOR_MAX_ATTEMPTS) {
          await new Promise((resolve) => setTimeout(resolve, FIND_DRAFT_EDITOR_DELAY_MS));
          return tryToFindDraftEditor(attempt + 1);
        }

        return null;
      };

      const editorRef = await tryToFindDraftEditor();
      if (!editorRef) {
        return;
      }

      let textSelection: { from: number; to: number } | null = null;

      editorRef.state.doc.descendants((node, nodeStartPosition) => {
        if (node.type.name !== BLOCK_NAME) {
          return false;
        }

        if (node.attrs.textItemId === textItemId) {
          textSelection = {
            from: nodeStartPosition + 1,
            to: nodeStartPosition + node.nodeSize,
          };
          return false;
        }
      });

      if (textSelection) {
        editorRef.commands.focus();
        editorRef.commands.setTextSelection(textSelection);
      }
    },
    [groups]
  );

  const isProjectFlagEnabled = (flagName: string): Boolean => {
    return Boolean(doc[0]?.feature_flags?.[flagName]);
  };

  const scrollToId = useState<string | null>(null);

  const { scrollToId: scrollTo } = useScroll({
    containerId: "projectContainer",
    offset: -100,
    duration: 500,
  });

  const handleScrollToTextItem = (textItemId: string) => {
    if (!textItemId) return;
    const [, setScrollToId] = scrollToId;
    setScrollToId(textItemId);
    scrollTo(textItemId);
  };

  return {
    activityFilter,
    allCommentHistoryFetched,
    allDocHistoryFetched,
    fetchCommentHistory,
    formatCommentThreads,
    commentHistory,
    commentHistoryIndex,
    commentHistoryLoading,
    compBeingEdited,
    compHistory,
    compHistoryLoading,
    createTextItemPanelStatus,
    displayApiIds,
    doc,
    docComponents,
    docHistory,
    docHistoryIndex,
    docHistoryLoading,
    fetchNewHistory,
    fetchProjectBranchInfo,
    findTextItemInProject,
    findTextItemsInProject,
    folder,
    forceMultiSelectOn,
    framePage,
    framePreviewsMap,
    getActiveVariantIndexForGroup,
    setActiveVariantOnGroup,
    getDocVariants,
    groupIndicesByGroupId,
    groupPreviewStateById,
    groupRenderState,
    groupState,
    groupTypeCounts,
    handleScrollToTextItem,
    highlightDraftTextItem,
    isProjectFlagEnabled,
    multiSelectOn,
    multiSelectedComps,
    multiSelectedIds,
    multiSelectedVariants,
    onDraftEditorDestroy,
    previewsJobId,
    projectBranchInfo,
    projectId,
    projectSummary,
    quickReplyCommentState,
    registerEditorReference,
    registerFocusFunction,
    resetCommentHistory,
    resetDocHistory,
    richTextModalOpen,
    scrollToId,
    search,
    selectedComp: [selectedComp, setSelectedComp],
    selectedDraftGroupId,
    selectedGroupId,
    selectedId,
    setActiveVariantIndexForGroup,
    setFramePreviewsMap,
    setPageByTextItemId,
    setVariants,
    showMergeBranchModal,
    showToast,
    suggestedCompId,
    unselectAll,
    updateActiveVariantIndexForAllGroups,
    updateDocHistory,
    updateFramePreviewsMap,
    updateProjectName,
    updateProjectFeatureFlags,
    variants,
    variantsLoading,
    ...page,
    ...resyncState,
    ...setupSuggestionsState,
    ...unsavedGroupChanges,
  };
};
