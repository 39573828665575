import logger from "@shared/utils/logger";
import axios, { AxiosInstance, AxiosRequestHeaders, AxiosRequestTransformer, CanceledError } from "axios";
import pako from "pako";
import RequestQueue from "./RequestQueue";

type AxiosInstanceWithRequestQueue = AxiosInstance & {
  requestQueue: RequestQueue;
};

const withRequestQueue = (axiosInstance: AxiosInstance) => {
  const requestQueue = new RequestQueue();
  Object.assign(axiosInstance, { requestQueue: requestQueue });
  return axiosInstance as AxiosInstanceWithRequestQueue;
};

const compressPayloadIfNeeded: AxiosRequestTransformer = function compressPayloadIfNeeded(
  data: any,
  headers: AxiosRequestHeaders
) {
  // compress strings if over 1KB
  if (typeof data === "string" && data.length > 1024) {
    headers["Content-Encoding"] = "gzip";
    let str = pako.gzip(data);
    console.log(
      `Compressing payload of ${data.length} bytes to ${str.length} bytes: ${Math.round(
        (str.length / data.length) * 100
      )}% of original size!`
    );
    return str;
  } else {
    return data;
  }
};

const createHttpClient = (): AxiosInstanceWithRequestQueue => {
  if (process.env.STORYBOOK === "true") {
    return axios.create({
      baseURL: process.env.BACKEND_URL,
      method: "get",
    }) as AxiosInstanceWithRequestQueue;
  }

  const http = withRequestQueue(
    axios.create({
      baseURL: process.env.BACKEND_URL,
      method: "get",
      headers: {
        "x-ditto-app": "web_app",
        "Content-Type": "application/json",
      },
      withCredentials: true,
      timeout: 30000,
      transformRequest: Array.isArray(axios.defaults.transformRequest)
        ? axios.defaults.transformRequest.concat(compressPayloadIfNeeded)
        : axios.defaults.transformRequest
        ? [axios.defaults.transformRequest, compressPayloadIfNeeded]
        : [compressPayloadIfNeeded],
    })
  );

  // a request starts while only 2 interceptors (caches current interceptors set)
  // request waits
  // add 3rd interceptor
  // request continues (with only 2 interceptors)

  /**
   * A race condition occurs when the app attempts to make authenticated requests before
   * Auth0 has initialized. This solves that race condition by queueing up requests in
   * a `RequestQueue` which will be cleared when Auth0 has finished initializing and an interceptor
   * has been added that retrieves the user's auth token.
   */
  http.interceptors.request.use(async (request) => {
    const getAuthToken = await http.requestQueue.addRequest();

    let token;
    try {
      token = await getAuthToken();
    } catch (error) {
      if (error.error === "login_required") {
        window.location.assign(`${process.env.BASE_URL}/login`);
        return {
          ...request,
          cancelToken: new axios.CancelToken((cancel) => cancel("Login required")),
        };
      }

      throw error;
    }

    request.headers.Authorization = token ? `Bearer ${token}` : "";

    return request;
  }, undefined);

  /**
   * Automatically log the user out when the API returns a 401 error response.
   */
  http.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        window?.analytics?.reset();
        window.location.reload();
      } else if (axios.isAxiosError(error)) {
        if (error instanceof CanceledError) {
          return Promise.reject(error);
        } else if (error.config) {
          error.message = `${error.config.method?.toUpperCase()} ${error.config.url} -- ${error.message}`;
          logger.error(error.message, { context: error }, error);
        }
      }
      return Promise.reject(error);
    }
  );

  return http;
};

export default createHttpClient;
