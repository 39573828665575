import { Editor } from "@tiptap/core";
import React, { useCallback, useEffect, useRef, useState } from "react";

import EnableLinkingButton from "./components/EnableLinkingButton";
import useDraftGroupState from "./state";

import DraftEditor from "./editor/DraftEditor";
import style from "./style.module.css";

import classNames from "classnames";
import { useHasProjectFeatureFlag } from "../../../../hooks/useHasProjectFeatureFlag";
import { Group, TextItem } from "../../state/types";
import { useProjectContext } from "../../state/useProjectState";
import RichTextDraftEditor from "./editor/RichTextDraftEditor";

export interface Props {
  group: Group;
  developerModeEnabled: boolean;
  isReadOnly: boolean;
  highlight?: string;
  hasUnsavedChanges?: boolean;
  setHasUnsavedChanges: (groupId: string) => void;
  onFocus: (groupId: string) => void;
  onBlur: (groupId: string) => void;
  onSaveTextItems: (groupId: string, textItems: TextItem[]) => void;
  onSaveGroupName: (groupId: string, groupName: string) => void;
  onEnableLinkingClick: (groupId: string, groupName: string, textItems: TextItem[]) => void;
  onSelectionUpdate: (textItemIds: string[]) => void;
  registerFocusFunction: (groupId: string, focus: () => void) => void;
  registerEditorReference: (groupId: string, editorRef: Editor) => void;
  onDestroy: (groupId: string) => void;
  className?: string;
}

const GroupDraft = (props: Props) => {
  const projectContext = useProjectContext();
  const groupId = props.group._id.toString();
  const groupContentHasFocus = useRef<boolean>(false);

  const [nameInputElement, setNameInputElement] = useState<HTMLInputElement | null>(null);

  const RICH_TEXT_ENABLED = useHasProjectFeatureFlag("rich_text");

  useEffect(() => {
    if (!(nameInputElement && groupId)) {
      return;
    }

    props.registerFocusFunction(groupId, () => {
      // if the content of the group already has focus,
      // don't steal it to focus the group's name input
      if (groupContentHasFocus.current) {
        return;
      }

      nameInputElement.focus();
    });
  }, [nameInputElement, groupId]);

  const onFocus = useCallback(() => {
    groupContentHasFocus.current = true;
    props.onFocus(groupId);
  }, [props.onFocus, groupId]);

  const onBlur = useCallback(() => {
    groupContentHasFocus.current = false;
    props.onBlur(groupId);
  }, [props.onBlur, groupId]);

  const onSaveGroupName = useCallback(
    (groupName: string) => props.onSaveGroupName(groupId, groupName),
    [props.onSaveGroupName, groupId]
  );

  const onEnableLinkingClick = useCallback(
    (groupName: string, textItems: TextItem[]) => props.onEnableLinkingClick(groupId, groupName, textItems),
    [props.onEnableLinkingClick, groupId]
  );

  const onDestroy = useCallback(() => props.onDestroy(groupId), [groupId]);

  const setHasUnsavedChanges = useCallback(
    () => props.setHasUnsavedChanges(groupId),
    [groupId, props.setHasUnsavedChanges]
  );

  const state = useDraftGroupState({
    group: props.group,
    developerModeEnabled: props.developerModeEnabled,
    isReadOnly: props.isReadOnly,
    highlight: props.highlight,
    onSaveTextItems: (t) => {},
    onSaveGroupName,
    onEnableLinkingClick,
    registerEditorReference: props.registerEditorReference,
    setHasUnsavedChanges,
    richTextEnabled: RICH_TEXT_ENABLED,
  });

  return (
    <div className={classNames([style.groupDraft, props.className])}>
      <div className={style.groupDraftTitleContainer}>
        <input
          value={state.groupName}
          disabled={props.isReadOnly}
          placeholder="Untitled Frame"
          className={style.groupDraftTitle}
          onChange={state.onGroupNameChange}
          onKeyUp={state.onKeyUp}
          ref={setNameInputElement}
        />
        {!props.isReadOnly && (
          <EnableLinkingButton
            show={state.showEnableLinkingButton}
            onClick={state.onEnableLinkingClick}
            className={style.enableLinkingButton}
          />
        )}
      </div>
      {projectContext.groupState[0].finished && (
        <div
          className={classNames({
            [style.groupDraftEditorContainer]: true,
            [style.groupDraftEditorContainerUntitledGroup]: props.group.name === "",
            [style.readOnly]: props.isReadOnly,
          })}
        >
          {RICH_TEXT_ENABLED ? (
            <RichTextDraftEditor
              onCreate={state.onEditorInitialization}
              isDisabled={props.isReadOnly || props.group.name === ""}
              onDestroy={onDestroy}
              onUpdate={state.onDraftTextChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSelectionUpdate={props.onSelectionUpdate}
              projectId={projectContext.doc[0]?._id || ""}
              groupId={groupId}
            />
          ) : (
            <DraftEditor
              onCreate={state.onEditorInitialization}
              isDisabled={props.isReadOnly || props.group.name === ""}
              onDestroy={onDestroy}
              onUpdate={state.onDraftTextChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSelectionUpdate={props.onSelectionUpdate}
              hasUnsavedChanges={props.hasUnsavedChanges}
              projectId={projectContext.doc[0]?._id || ""}
              groupId={groupId}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GroupDraft;
