import { useWorkspace } from "@/store/workspaceContext";
import { VARIANT_STATUSES_ENABLED } from "@/utils/featureFlags";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CodeIcon from "@mui/icons-material/Code";
import DeleteIcon from "@mui/icons-material/Delete";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import NotesIcon from "@mui/icons-material/Notes";
import TollIcon from "@mui/icons-material/Toll";
import VisibilityOnIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import classnames from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { isSuperset } from "../../../shared/utils/array";
import { TOAST_TYPES } from "../../defs";
import { useHasProjectFeatureFlag } from "../../hooks/useHasProjectFeatureFlag";
import { useHasTextChanges } from "../../hooks/useHasTextChanges";
import http, { API } from "../../http";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import { INTERCOM_ATTR } from "../../utils/constants";
import { parsePluralInput } from "../../utils/pluralization";
import DeleteTextItemModal from "../../views/Project/components/DeleteTextItemModal";
import { useProjectContext } from "../../views/Project/state/useProjectState";
import CompactLabel from "../CompactLabel";
import CompactLabelWithEditMode, { useEditModeState } from "../CompactLabel/CompactLabelWithEditMode";
import DuplicateComponentsNudge from "../DuplicateComponentsNudge";
import FigmaComponentTextNodeLabel from "../FigmaComponentTextNodeLabel";
import OutlineButton from "../OutlineButton";
import StatusSelect from "../StatusSelect";
import TextItemTextInputs from "../TextItemTextInputs/TextItemTextInputs";
import UserSelect from "../UserSelect";
import ApiID from "../api-id";
import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";
import ComponentModal from "../componentmodal/componentmodal";
import TagInput from "../tag-input/tag-input";
import VariablesPanel from "../variable-interpolations/VariablesPanel";
import VariantIcon from "../variant-icon/VariantIcon";
import style from "./style.module.css";

const BASE_VARIANT_ID = "__base__";

const EditComp = ({
  commentEditor,
  origComp,
  setOrigComp,
  comp,
  setComp,
  selectedId,
  selectedVariant,
  doc_ID,
  doc_name,
  duplicateComps,
  duplicatesContainsVariants,
  handleDocUpdate,
  handleHistoryUpdate,
  onSearch,
  fetchCompInfo,
  forceShowToast,
  hidingAvailable,
  tagSuggestions,
  getWorkspaceTags,
  unselectAll,
  frameVariants,
  selectedGroupId,
  isDeveloperModeEnabled,
  setShowMultiAttachCompToast,
  onSaveSuggestion,
  isMergedBranch,
  isLockedProject,
  inSampleProject,
  ...otherProps
}) => {
  const {
    // optional prop to get notified when a component is attached to a single text tem
    onSingleAttachComponent,
    // optional prop to get notified when a component is attached to multiple text items
    onMultiAttachComponent,
  } = otherProps;
  const [tags, setTags] = useState([]);
  const [editDupes, setEditDupes] = useState(false);
  const [refreshComp, setRefreshComp] = useState(false);
  const [shouldResetComp, setShouldResetComp] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [componentModalOpen, setComponentModalOpen] = useState(false);
  const [deleteTextItemModalOpen, setDeleteTextItemModalOpen] = useState(false);
  const [shouldComponentizeDuplicates, setShouldComponentizeDuplicates] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showPlurals, setShowPlurals] = useState(false);
  const [showVariantPlurals, setShowVariantPlurals] = useState(false);
  const [textChanged, setTextChanged] = useState(false);

  const [editMode, setEditMode] = useEditModeState();
  const onModeChange = async (newMode) => {
    if (newMode !== editMode && newMode === "suggesting") {
      // custom unsaved changes modal text for saving a suggestion
      setModalParams({
        modalText: {
          body: "Before switching to suggestion mode, would you like to save your edits?",
        },
      });

      // ensure that "sync edits to duplicates" is off
      await new Promise((resolve) => checkDetailPanelChanges(resolve));

      // switch the unsaved changes modal back to default text
      setModalParams({ modalText: undefined });

      setEditDupes(false);
    }
    setEditMode(newMode);
  };

  useEffect(
    function resetPanelOnSelectionChange() {
      setEditMode("editing");
      setShowNotes(false);
      setShowTags(false);
      setShowPlurals(false);
    },
    [selectedId]
  );

  const { users, workspaceInfo } = useWorkspace();
  const formattedUserOptions = useMemo(() => {
    if (!users) return [];
    return users.map((user) => ({
      id: user._id,
      name: user.name,
    }));
  }, [users]);

  const [isVariant, setIsVariant] = useState(false);
  const [variantStatus, setVariantStatus] = useState(null);
  // { text, variables }
  const valueBeingEdited = useRef();

  const isRichTextFlagOn = useHasProjectFeatureFlag("rich_text");
  const isEditEnabled = userHasResourcePermission("project_folder:edit");

  const manualApiIdEditsAllowed = isEditEnabled && !workspaceInfo?.config?.projects?.apiIdPreventManualEdits;

  // We need to refactor this component to handle the within project
  // and not in project use case better, most likely create two different comps
  // Ticket for this is here https://linear.app/dittowords/issue/DIT-2782/refactor-editcompjsx-to-handle-on-search-page-vs-on-project-page-use
  const projectContext = onSearch
    ? {
        richTextModalOpen: [undefined, undefined],
      }
    : useProjectContext();

  const {
    richTextModalOpen: [, setRichTextModalOpen],
  } = projectContext;

  const variantInstance = useMemo(() => {
    if (selectedVariant?.id && selectedVariant?.id !== "__base__" && origComp?.variants) {
      for (var variant of origComp.variants) {
        if (variant?.variantId === selectedVariant?.id) {
          return variant;
        }
      }
      return {
        variantId: selectedVariant.id,
        text: "",
        lastSync: null,
        variables: [],
        plurals: [],
        status: null,
      };
    }
    return null;
  }, [origComp, selectedVariant]);

  const {
    canSaveEdits: [canSaveEdits, setCanSaveEdits],
    setModalParams,
    checkDetailPanelChanges,
  } = useContext(UnsavedChangesContext);

  const hasUnsavedChanges = useMemo(() => {
    if (!(origComp && comp)) {
      return false;
    }

    const assigneeChanged = comp.assignee != origComp.assignee;
    const statusChanged = isVariant
      ? variantStatus !== (variantInstance?.status ?? comp.status)
      : comp.status !== origComp.status;
    const notesChanged = comp.notes !== origComp.notes && !(comp.notes === "" && !origComp.notes);

    const tagsChanged = comp.tags.sort().join("") !== origComp.tags.sort().join("");

    const characterLimitChanged = comp.characterLimit !== origComp.characterLimit;

    return textChanged || assigneeChanged || statusChanged || notesChanged || tagsChanged || characterLimitChanged;
  }, [origComp, comp, textChanged, variantStatus, selectedVariant, variantInstance]);

  useEffect(
    function syncUnsavedChangesWithCanEdit() {
      setCanSaveEdits(hasUnsavedChanges);
    },
    [hasUnsavedChanges]
  );

  useEffect(() => {
    const isNonBaseVariant = selectedVariant && selectedVariant.id !== "__base__";
    setIsVariant(isNonBaseVariant);

    if (isNonBaseVariant && variantInstance) {
      setVariantStatus(variantInstance.status ?? comp.status);
    }

    resetChanges();
  }, [selectedVariant, variantInstance]);

  const showDuplicateNudge =
    isEditEnabled &&
    (!selectedVariant || selectedVariant?.id === "__base__") &&
    !isLockedProject &&
    comp?.variants?.length === 0 &&
    !duplicatesContainsVariants;

  useEffect(() => {
    if (refreshComp) {
      forceShowToast({
        title: "⚠️ Error saving edit",
        body: "Sorry! We had an issue saving your edit. Please try again.",
        type: TOAST_TYPES.default,
        autoHide: false,
      });
      setRefreshComp(false);
    }
  }, [refreshComp]);

  const fetchData = async () => {
    if (origComp) {
      setComp(origComp);
      if (origComp.tags) {
        const tags = origComp.tags.map((tag, index) => {
          return {
            id: index,
            name: tag,
          };
        });
        setTags(tags);
      }
      setCanSaveEdits(false);
      setEditDupes(false);
    }
  };

  const handleStatusChange = (status) => {
    if (isVariant) {
      setVariantStatus(status);
    } else {
      setComp({ ...comp, status });
    }
  };

  const handleNotesChange = (e) => {
    setComp({ ...comp, notes: e.target.value });
  };

  const handleAssigneeChange = (userId) => {
    setComp({ ...comp, assignee: userId });
  };

  const onDeleteTag = (i) => {
    const curr_tags = tags.slice(0);
    curr_tags.splice(i, 1);
    setTags(curr_tags);
    setComp({ ...comp, tags: curr_tags.map((tag) => tag.name) });
  };
  const onAddTag = (tag) => {
    const upper = tag.name.toUpperCase();
    const match = tags.filter((existing) => existing.name === upper);
    if (match.length === 0) {
      const curr_tags = [...tags, { name: upper }];
      setTags(curr_tags);
      setComp({ ...comp, tags: curr_tags.map((tag) => tag.name) });
    }
  };

  const setComponentIsHidden = async (is_hidden) => {
    try {
      const { url, body } = API.comp.post.setIsHidden;
      const { data: returnedComp } = await http.post(
        url(selectedId),
        body({
          is_hidden,
        })
      );
      setOrigComp(returnedComp);
      setComp(returnedComp);
      handleDocUpdate([selectedId]);
    } catch (error) {
      console.error("Error in hiding component: ", error.message);
      forceShowToast({
        title: "⚠️ Error hiding text item",
        body: "Sorry! We had an issue hiding your text item. ",
        type: TOAST_TYPES.edit_error,
        autoHide: true,
      });
    }
  };

  const handleEditDupes = (e) => {
    setEditDupes(!editDupes);
  };
  const resetChanges = () => {
    fetchData();
    setVariantStatus(variantInstance?.status ?? comp.status);
    setShouldResetComp(true);
    setCanSaveEdits(false);
    setEditMode("editing");

    setTimeout(() => {
      setShouldResetComp(false);
    }, 100);
  };

  const saveCharacterLimit = useCallback(async () => {
    if (comp.characterLimit !== origComp?.characterLimit) {
      const { url, body } = API.comp.post.updateCharacterLimit;
      const updateURL = url(origComp._id);
      await http.post(
        updateURL,
        body({
          characterLimit: comp.characterLimit,
        })
      );
    }
  }, [comp, origComp]);

  const saveEdits = async () => {
    //check to make sure comps have ids
    if (!comp || !origComp) {
      return;
    }
    //check to make sure origComp and comp are of the same ActualComponent
    if (origComp._id !== comp._id) {
      return;
    }
    const latest_tags = tags.map((tag) => tag.name);

    let updateURL, reqBody;

    if (isEditEnabled) {
      const { url, body } = API.comp.post.update;
      updateURL = url(selectedId);

      const { allVariables, baseText, baseRichText, plurals } = parsePluralInput(valueBeingEdited, comp.text);

      reqBody = body({
        newCompInfo: {
          assignee: comp.assignee || null,
          status: comp.status,
          text: baseText,
          rich_text: baseRichText,
          variables: allVariables,
          notes: comp.notes,
          tags: latest_tags,
          plurals,
          characterLimit: comp.characterLimit,
        },
        originalText: origComp.text,
        editAll: editDupes,
        changeInfo: {
          doc_name: doc_name,
          doc_id: doc_ID,
        },
      });
    } else {
      const { url, body } = API.comp.post.updateStatusById;
      updateURL = url(selectedId);
      reqBody = body({
        newCompInfo: { status: comp.status },
        editAll: editDupes,
      });
    }

    try {
      setIsSaving(true);
      const { data: response } = await http.post(updateURL, reqBody);
      if (response.error) {
        if (response.error === "no_dupes") {
          console.error("in editcomp.jsx, error is no_dupes");
          await fetchCompInfo();
          setRefreshComp(true);
        }
        setIsSaving(false);
        return;
      }

      await saveCharacterLimit();

      // if no errors, we're finished editing
      setCanSaveEdits(false);
      setShowNotes(false);
      setShowTags(false);

      if (setOrigComp !== undefined) {
        setOrigComp({ ...origComp, ...reqBody.newCompInfo });
      }
      if (response.compIDs) {
        handleDocUpdate(response.compIDs);
      } else {
        handleDocUpdate([selectedId]);
      }
      handleHistoryUpdate();
      if (
        !isSuperset(
          latest_tags,
          tagSuggestions.map((item) => item.name.toUpperCase())
        )
      ) {
        getWorkspaceTags();
      }
      setIsSaving(false);
      analytics.page();
    } catch (error) {
      setIsSaving(false);
      if (forceShowToast !== undefined) {
        forceShowToast({
          title: "⚠️ Error saving edit",
          body: "Sorry! We had an issue saving your edit. ",
          type: TOAST_TYPES.edit_error,
          autoHide: true,
        });
      }
      console.error("in editcomp.jsx: ", error);
    }
  };

  const handleCharacterLimitChange = async (newCharacterLimit) => {
    setComp({
      ...comp,
      characterLimit: newCharacterLimit,
    });
  };

  const saveSuggestion = async () => {
    if (!isEditEnabled) {
      return;
    }

    setIsSaving(true);

    const [
      {
        value: { text, richText, variables },
      },
    ] = valueBeingEdited.current;

    await onSaveSuggestion({ text, richText, variables });

    resetChanges();
    setIsSaving(false);
    setEditMode("editing");
  };

  const saveVariantEdits = async (shouldUpdateOrigComp = true) => {
    try {
      setIsSaving(true);
      const { allVariables, baseText, baseRichText, plurals } = parsePluralInput(
        valueBeingEdited,
        variantInstance?.text || ""
      );

      const { url, body } = API.variant.post.editVariantTextForComp;
      const { data: updatedComp } = await http.post(
        url(selectedId, selectedVariant.id),
        body({
          updatedVariantInfo: {
            text: baseText,
            rich_text: baseRichText,
            variables: allVariables,
            plurals,
            status: VARIANT_STATUSES_ENABLED ? variantStatus : undefined,
          },
          fromFigma: false,
        })
      );
      setIsSaving(false);
      if (shouldUpdateOrigComp) setOrigComp(updatedComp);
      setComp(updatedComp);

      // update selected variant

      handleDocUpdate([selectedId]);
      handleHistoryUpdate();
    } catch (error) {
      setIsSaving(false);
      if (forceShowToast !== undefined) {
        forceShowToast({
          title: "⚠️ Error saving edit",
          body: "Sorry! We had an issue saving your edit. ",
          type: TOAST_TYPES.edit_error,
          autoHide: true,
        });
      }
      console.error("Error in editing variant text: ", error.message);
    }
  };

  useEffect(() => {
    if (origComp) {
      fetchData();
    }
  }, [origComp]);

  const toggleComponentModal = () => {
    if (componentModalOpen) setShouldComponentizeDuplicates(false);
    setComponentModalOpen(!componentModalOpen);
  };

  const onComponentizeDuplicates = () => {
    window?.analytics?.track("Componentize Duplicates Clicked");
    setShouldComponentizeDuplicates(true);
    setComponentModalOpen(true);
  };

  const getTextHasChanged = useHasTextChanges(comp, isRichTextFlagOn);

  const handleTextChange = (value) => {
    if (shouldResetComp || !value.length) return;

    const textHasChanged = getTextHasChanged(value, selectedVariant?.id || BASE_VARIANT_ID);
    setTextChanged(textHasChanged);
    valueBeingEdited.current = value;
  };

  const saveTextIdEdit = async (newApiId) => {
    try {
      const { url, body } = API.comp.post.editApiId;
      const { data: comp } = await http.post(
        url(selectedId),
        body({
          doc_ID,
          newApiId: newApiId,
          from: "web_app",
        })
      );

      setOrigComp(comp);
      setComp(comp);
      handleDocUpdate([selectedId]);
      handleHistoryUpdate();
      return "success";
    } catch (error) {
      if (error?.response?.status === 409) {
        return "duplicate-api-id";
      }
      console.error("Error in editing text apiID: ", error.message);
      return "Unable to save edit";
    }
  };

  useEffect(() => {
    if (canSaveEdits) {
      setModalParams({
        saveCallback: async () => {
          if (editMode === "suggesting") {
            await saveSuggestion();
          } else if (isVariant) {
            await saveVariantEdits(false);
          } else {
            await saveEdits(false);
          }

          setCanSaveEdits(false);
        },
        discardCallback: async () => {
          setCanSaveEdits(false);
          resetChanges();
        },
      });
    }
  }, [
    canSaveEdits,
    isEditEnabled,
    comp,
    origComp,
    selectedId,
    editMode,
    setModalParams,
    setCanSaveEdits,
    saveVariantEdits,
    saveEdits,
    resetChanges,
  ]);

  // Submit button props
  const onSubmitButtonClick = () => {
    if (isVariant) {
      saveVariantEdits();
      return;
    }

    if (editMode === "suggesting") {
      saveSuggestion();
      return;
    }

    saveEdits();
  };
  const submitButtonDisabled = !hasUnsavedChanges || isSaving;
  const submitButtonClass = classnames({
    [style.suggestEditsSubmitButton]: editMode === "suggesting",
  });
  const submitButtonText = useMemo(() => {
    if (isSaving) {
      return "Saving...";
    }
    if (editMode === "editing") {
      return "Save Edits";
    }
    if (editMode === "suggesting") {
      return "Save Suggestion";
    }

    throw new Error(`Invalid edit mode: ${editMode}`);
  }, [isSaving, editMode]);
  //

  // Cancel button props
  const onCancelButtonClick = () => resetChanges();
  const cancelButtonClass = classnames({
    [style.suggestEditsCancelButton]: editMode === "suggesting",
  });
  const cancelButtonDisabled = !canSaveEdits || isSaving;

  const metadataDisabled = editMode === "suggesting";

  const hasVariantWithTextOrStatus =
    origComp.variants && origComp.variants.some((v) => v.status !== "NONE" || Boolean(v.text));

  return (
    <div className={style.container}>
      {isEditEnabled && (
        <>
          {origComp && !origComp.is_hidden && !onSearch && !isVariant && (
            <div>
              <div className={classnames(style.hideCompSection, "hide-component")}>
                <div className={style.hideComp} onClick={() => toggleComponentModal()}>
                  <ImportContactsIcon className={style.icon} />
                  Create or attach component
                </div>
              </div>
              {hidingAvailable && !hasVariantWithTextOrStatus && (
                <div className={classnames(style.hideCompSection, "hide-component")}>
                  <div className={style.hideComp} onClick={() => setComponentIsHidden(true)}>
                    <VisibilityOffIcon className={style.icon} />
                    Hide from view
                  </div>
                </div>
              )}
            </div>
          )}
          {origComp && origComp.is_hidden && !onSearch && hidingAvailable && !isVariant && (
            <div className={style.unhideCompSection}>
              <div className={style.hideComp} onClick={() => setComponentIsHidden(false)}>
                <VisibilityOnIcon className={style.icon} />
                Unhide from view
              </div>
            </div>
          )}
        </>
      )}
      {origComp && !origComp.is_hidden && (
        <div
          className={classnames(style.editComp, "edit-component")}
          data-tour={INTERCOM_ATTR.EDIT_COMP}
          data-testid="edit-comp-area"
        >
          <div className={style.form}>
            {!isEditEnabled && <div className={style.warning}>You don't have edit access to this text.</div>}
            {canSaveEdits && <div className={style.warning}>You have unsaved changes!</div>}
            <FigmaComponentTextNodeLabel type={comp?.integrations?.figma?.type} />
            <StatusSelect
              status={isVariant && VARIANT_STATUSES_ENABLED ? variantStatus : comp.status}
              handleStatusChange={handleStatusChange}
              disabled={
                // remove isVariant condition entirely when removing flag
                (isVariant && !VARIANT_STATUSES_ENABLED) || metadataDisabled || isLockedProject
              }
            />
            <TextItemTextInputs
              shouldShowRichText={isRichTextFlagOn}
              textItem={
                shouldResetComp
                  ? {
                      text: "",
                      variables: [],
                      plurals: [],
                      reset: true,
                    }
                  : comp
              }
              legacyHandleTextChange={(value) => (isEditEnabled && !isVariant ? handleTextChange(value) : () => {})}
              readonly={!isEditEnabled || isVariant || isLockedProject}
              isBaseText={true}
              isVariant={isVariant}
              textLabelLeft={
                <>
                  {(onSearch || isVariant || isLockedProject) && <CompactLabel text="Text" Icon={NotesIcon} />}
                  {!(onSearch || isVariant || isLockedProject) && (
                    <CompactLabelWithEditMode
                      text="Text"
                      Icon={NotesIcon}
                      mode={editMode}
                      onModeChange={onModeChange}
                      disabled={!isEditEnabled}
                    />
                  )}
                </>
              }
              textInputClassName={classnames({
                [style.textInputSuggestionMode]: editMode === "suggesting",
              })}
              shouldShowPlurals={[showPlurals, setShowPlurals]}
              setRichTextModalOpen={setRichTextModalOpen}
              pluralInputsDisabled={editMode === "suggesting"}
              handleCharacterLimitChange={handleCharacterLimitChange}
              inSampleProject={inSampleProject}
            />
            {isVariant && selectedVariant && variantInstance && (
              <div className={style.variantTextWrapper}>
                <TextItemTextInputs
                  shouldShowRichText={isRichTextFlagOn}
                  textItem={
                    shouldResetComp
                      ? {
                          text: "",
                          variables: [],
                          plurals: [],
                          reset: true,
                        }
                      : {
                          ...variantInstance,
                          characterLimit: comp.characterLimit,
                        }
                  }
                  legacyHandleTextChange={handleTextChange}
                  readonly={!isEditEnabled || isLockedProject}
                  isBaseText={false}
                  isVariant={true}
                  textLabelLeft={<CompactLabel text="Variant Text" Icon={VariantIcon} />}
                  shouldShowPlurals={[showVariantPlurals, setShowVariantPlurals]}
                  setRichTextModalOpen={setRichTextModalOpen}
                  handleCharacterLimitChange={handleCharacterLimitChange}
                  inSampleProject={inSampleProject}
                />
              </div>
            )}
            <div className={style.textAreaButtons}>
              {!showAssign && !origComp.assignee && !isVariant && (
                <OutlineButton
                  Icon={AccountCircleIcon}
                  text="Assign"
                  onClick={() => setShowAssign(true)}
                  disabled={metadataDisabled || !isEditEnabled}
                />
              )}
              {!(comp.notes && comp.notes.length > 0) && !showNotes && !isVariant && (
                <OutlineButton
                  Icon={InfoOutlinedIcon}
                  text="Notes"
                  onClick={() => setShowNotes(true)}
                  disabled={metadataDisabled || !isEditEnabled}
                />
              )}
              {origComp.tags.length === 0 && !showTags && !isVariant && (
                <OutlineButton
                  Icon={LocalOfferIcon}
                  text="Tags"
                  onClick={() => setShowTags(true)}
                  disabled={metadataDisabled || !isEditEnabled}
                />
              )}
              {!showPlurals && !isVariant && (
                <OutlineButton
                  Icon={TollIcon}
                  text="Plurals"
                  onClick={() => setShowPlurals(true)}
                  disabled={metadataDisabled || !isEditEnabled}
                />
              )}
              {!showVariantPlurals && isVariant && (
                <OutlineButton
                  Icon={TollIcon}
                  text="Plurals"
                  onClick={() => setShowVariantPlurals(true)}
                  disabled={metadataDisabled || !isEditEnabled}
                />
              )}
            </div>

            {(showAssign || origComp.assignee) && (
              <div className={style.assignArea}>
                <CompactLabel Icon={AccountCircleIcon} text="Assign" />
                <UserSelect
                  placeholder="No teammate assigned"
                  setSelectedUserId={handleAssigneeChange}
                  selectedUserId={comp.assignee}
                  users={formattedUserOptions}
                  disabled={!isEditEnabled || isVariant || metadataDisabled}
                />
              </div>
            )}
            {(showNotes || (comp.notes && comp.notes.length > 0)) && (
              <div className={style.notesArea}>
                <CompactLabel Icon={InfoOutlinedIcon} text="Notes" />
                <div className={style.textBoxWrapper}>
                  <TextareaAutosize
                    data-testid="notes-input"
                    placeholder="No notes written."
                    disabled={!isEditEnabled || isVariant || metadataDisabled}
                    value={comp.notes ? comp.notes : ""}
                    onChange={handleNotesChange}
                  />
                </div>
              </div>
            )}
            {(showTags || origComp.tags.length > 0) && (
              <div className={style.tagsArea}>
                <CompactLabel Icon={LocalOfferIcon} text="Tags" />
                <TagInput
                  tags={tags}
                  disabled={!isEditEnabled || isVariant || metadataDisabled}
                  inputAttributes={{
                    disabled: !isEditEnabled || isVariant,
                  }}
                  onDeleteTag={isEditEnabled ? onDeleteTag : () => {}}
                  onAddTag={onAddTag}
                  tagSuggestions={tagSuggestions}
                  placeholder={!isEditEnabled || isVariant ? (tags.length === 0 ? "No tags" : "") : "Add new tag"}
                />
              </div>
            )}
            {duplicateComps?.length > 1 && (
              <>
                <div
                  htmlFor="editDupes"
                  onClick={handleEditDupes}
                  className={classnames({
                    [style.check]: true,
                    [style.checkDisabled]: !isEditEnabled || isVariant || metadataDisabled,
                  })}
                >
                  <input
                    type="checkbox"
                    name="editDupes"
                    checked={editDupes}
                    onChange={handleEditDupes}
                    disabled={isVariant || metadataDisabled || !isEditEnabled}
                  />
                  <span>
                    Sync edits to {duplicateComps.length - 1} other duplicate
                    {duplicateComps.length - 1 > 1 ? "s" : ""} in project
                  </span>
                </div>
                {showDuplicateNudge && (
                  <DuplicateComponentsNudge
                    handleComponentizeDuplicates={onComponentizeDuplicates}
                    numDuplicates={duplicateComps.length}
                  />
                )}
              </>
            )}
            {!isMergedBranch && (
              <div className={style.buttons}>
                <ButtonSecondary
                  text="Cancel"
                  onClick={onCancelButtonClick}
                  disabled={cancelButtonDisabled}
                  className={cancelButtonClass}
                />
                <ButtonPrimary
                  data-testid="edit-text-item-submit-button"
                  text={submitButtonText}
                  onClick={onSubmitButtonClick}
                  disabled={submitButtonDisabled}
                  className={submitButtonClass}
                />
              </div>
            )}
          </div>
          {!isMergedBranch && Boolean(commentEditor) && <>{commentEditor}</>}
        </div>
      )}
      <VariablesPanel
        isVariant={isVariant}
        variables={isVariant ? variantInstance?.variables || [] : comp?.variables || []}
      />
      {comp && comp.apiID && (
        <div className={style.idSection} data-testid="id-section">
          <CompactLabel text="Text Item ID" Icon={CodeIcon} className={style.idLabel} />
          <ApiID
            id={comp.apiID}
            canEdit={manualApiIdEditsAllowed}
            onEdit={saveTextIdEdit}
            isDuplicateId={(text) => [].includes(text)}
          />
        </div>
      )}
      {isEditEnabled && comp && !comp.figma_node_ID && (
        <div className={style.deleteSection} onClick={() => setDeleteTextItemModalOpen(true)}>
          <DeleteIcon className={style.icon} />
          <span>Delete text item</span>
        </div>
      )}
      {componentModalOpen && (
        <ComponentModal
          onHide={toggleComponentModal}
          handleDocUpdate={handleDocUpdate}
          fetchCompInfo={fetchCompInfo}
          comp={origComp}
          shouldComponentizeDuplicates={shouldComponentizeDuplicates}
          duplicateComps={duplicateComps}
          handleHistoryUpdate={handleHistoryUpdate}
          frameVariants={frameVariants}
          selectedGroupId={selectedGroupId}
          setShowMultiAttachCompToast={setShowMultiAttachCompToast}
          isSampleProject={inSampleProject}
          onSingleAttach={onSingleAttachComponent}
          onMultiAttach={onMultiAttachComponent}
        />
      )}
      {comp && !comp.figma_node_ID && !onSearch && (
        <DeleteTextItemModal
          show={deleteTextItemModalOpen}
          onHide={() => setDeleteTextItemModalOpen(false)}
          projectId={doc_ID}
          groupId={selectedGroupId}
          textItemId={comp._id}
          unselectAll={unselectAll}
          isDeveloperModeEnabled={isDeveloperModeEnabled}
        />
      )}
    </div>
  );
};

export default EditComp;
