import { Editor } from "@tiptap/core";
import { useEffect, useMemo, useRef, useState } from "react";

import useDebouncedCallback from "../../../../../../util/useDebouncedCallback";
import useOnDraftTextChange from "./useOnDraftTextChange";

import { Group, TextItem } from "../../../state/types";
import { useProjectContext } from "../../../state/useProjectState";
import { getEditorHasContent, textItemsToEditorJson, useEditorJsonToTextItems } from "../editor/lib";
import { EditorJSON } from "../editor/types";

// The buffer time to give for the editor to initialize itself
// prior to beginning to allow sending it update commands.
//
// Setting this to too low of a value can cause bugs due to
// the selection state getting artificially updated when the editor
// mounts, and that selection state update causing text items to be
// arbitrarily selected.
const EDITOR_INITIALIZATION_BUFFER_MS = 100;

interface Args {
  group: Group;
  developerModeEnabled: boolean;
  isReadOnly: boolean;
  highlight?: string;
  onSaveTextItems: (textItems: TextItem[]) => void;
  onSaveGroupName: (groupName: string) => void;
  onEnableLinkingClick: (groupName: string, textItems: TextItem[]) => void;
  registerEditorReference: (groupId: string, editorRef: Editor) => void;
  setHasUnsavedChanges: () => void;
  richTextEnabled: Boolean;
}

const useDraftGroupState = (args: Args) => {
  const {
    group,
    developerModeEnabled,
    isReadOnly,
    highlight,
    onSaveTextItems,
    onSaveGroupName,
    setHasUnsavedChanges,
    richTextEnabled,
  } = args;

  const {
    projectSummary: [, refreshProjectSummary],
  } = useProjectContext();

  const editorRef = useRef<Editor | null>(null);
  const editorInitialized = useRef(false);

  const [groupName, setGroupName] = useState(group.name);

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    editorRef.current.commands.setHighlight(highlight ? new RegExp(highlight, "gi") : null);
  }, [highlight]);

  // reset the local, mutable groupName value whenever the name of the group
  // passed as a prop changes
  useEffect(() => setGroupName(group.name), [group.name, setGroupName]);

  // This is only used to determine whether or not the "Enable Linking" button should be shown
  const [editorHasContent, setEditorHasContent] = useState(false);

  // need to wait on group.name to be set -- that means we've succesfully created the group on the backend.
  const showEnableLinkingButton = Boolean(editorHasContent && groupName && group.name);

  const onDraftTextChange = useOnDraftTextChange({
    setEditorHasContent,
    onSaveTextItems,
    developerModeEnabled,
    initialTextItemCount: group.comps.length,
    setHasUnsavedChanges,
  });

  const onEditorInitialization = ({ editor }: { editor: Editor }) => {
    editorRef.current = editor;
    args.registerEditorReference(group._id.toString(), editor);

    const json = editor.getJSON() as EditorJSON;
    setEditorHasContent(getEditorHasContent(json));

    setTimeout(() => {
      editorInitialized.current = true;
    }, EDITOR_INITIALIZATION_BUFFER_MS);
  };

  const onSaveGroupNameDebounced = useDebouncedCallback<string>(onSaveGroupName, 200);

  const onGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newGroupName = e.target.value;
    setGroupName(newGroupName);

    if (newGroupName === group.name) {
      return;
    }

    onSaveGroupNameDebounced(newGroupName);
  };

  const editorJsonToTextItems = useEditorJsonToTextItems(developerModeEnabled);
  const onEnableLinkingClick = () => {
    if (!editorRef.current) {
      return;
    }

    // Clear queued save operations from recent edits to the group name or
    // the group's text items, preventing a race condition occurring w/ parallel
    // requests to the back-end. We're able to do this without losing data
    // bc the request sent by clicking "Enable Linking" also contains the
    // group's name and text items in the payload.
    onSaveGroupNameDebounced.clear();

    const json = editorRef.current.getJSON() as EditorJSON;
    args.onEnableLinkingClick(groupName, editorJsonToTextItems(json));
    refreshProjectSummary();
  };

  const onKeyUp = (e: React.KeyboardEvent) => {
    const key = e.key;
    // Focus on group content, when you hit 'enter' on the group name
    if (key === "Enter") {
      editorRef.current?.commands.focus();
    }
  };

  const editorValue = useMemo<EditorJSON>(() => {
    return textItemsToEditorJson(group.comps, isReadOnly, richTextEnabled);
  }, [group.comps, isReadOnly, richTextEnabled]);

  return {
    groupName,
    showEnableLinkingButton,
    editorValue,
    onGroupNameChange,
    onKeyUp,
    onDraftTextChange,
    onEditorInitialization,
    onEnableLinkingClick,
  };
};

export default useDraftGroupState;
