import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { searchParamsAtom } from "../stores/SelectionStore";

/**
 * This component is responsible for updating the search params atom.
 * It should only be consumed once at the root of the projects page.
 * @returns null No UI is rendered by this component.
 */
export function SearchParamsFetcher() {
  const setSearchParams = useSetAtom(searchParamsAtom);
  const location = useLocation();

  useEffect(
    function updateParams() {
      const searchParams = new URLSearchParams(location.search);
      setSearchParams(searchParams);
    },
    [location.search, setSearchParams]
  );

  return null;
}
