import Document from "@tiptap/extension-document";
import DropCursor from "@tiptap/extension-dropcursor";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Typography from "@tiptap/extension-typography";
import { Extensions } from "@tiptap/react";

import DraftBlock, { BLOCK_NAME } from "./nodes/DraftBlockNode";

import DevTools from "./extensions/DevTools";
import Focus from "./extensions/FocusExtension";
import Highlighter from "./extensions/HighlighterExtension";
import Placeholder from "./extensions/PlaceholderExtension";

import style from "../style.module.css";

export type EditorNodeNames = "document" | "draftBlock" | "paragraph" | "text";

export const EditorNodes = [
  // Extending the document here enforces that its children
  // are always composed of at least one `draftBlock`, which
  // saves us in edge casey situations like Command + A -> Delete.
  Document.extend({
    content: `${BLOCK_NAME}+`,
  }),
  DraftBlock,
  Paragraph,
  Text,
];

export const EditorExtensions: Extensions = [
  Highlighter,

  // Responsible for the preview line that shows up while dragging
  // a node to indicate where it is going to drop.
  DropCursor.configure({
    class: style.dropCursor,
    width: 1,
  }),

  // Responsible for showing placeholder text for the currently
  // selected paragraph node if the currently selected block is empty
  Placeholder,

  // Automatically convert common text patterns into the correct
  // typographic characters e.g. --, -> !=, etc
  Typography,

  // Automatically add a class to all of the blocks that are in the current
  // selection - used specifically for when a user highlights multiple
  // nodes for dragging and dropping
  Focus,

  ...(process.env.IS_LOCAL === "true" ? [DevTools] : []),
];
