import components__figma_plugin__base from "./components__figma-plugin__base.json";
import components__root__base from "./components__root__base.json";
import components__root__commenter from "./components__root__commenter.json";
import components__test__base from "./components__test__base.json";
import onboarding_checklist_help_center from "./onboarding-checklist-help-center__base.json";
import personalized_nux_modals from "./personalized-nux-modals__base.json";
import personalized_nux_modals1 from "./personalized-nux-modals__commenter.json";

export default {
  project_630d31fb22f3d9e524d780b9: {
    base: { ...onboarding_checklist_help_center },
  },
  project_61b8d26105f8f400e97fcd14: {
    base: { ...personalized_nux_modals },
    commenter: { ...personalized_nux_modals1 },
  },
  ditto_component_library: {
    base: { ...components__figma_plugin__base, ...components__root__base, ...components__test__base },
    commenter: { ...components__root__commenter },
  },
};
