import { Extension } from "@tiptap/core";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    errorhighlighter: {
      setErrorHighlight: (start: number, end: number) => ReturnType;
    };
  }
}

function generateHighlights(doc: ProsemirrorNode, start: number, end: number) {
  const decorations: Decoration[] = [];

  doc.descendants((node: any, position: number) => {
    if (!(node.isText || node.type.name === "variable")) {
      return;
    }

    const text = node.text || node.attrs.text;
    if (text.length < start) {
      start -= text.length;
      end -= text.length;
    } else if (end > 0) {
      const beginHighlight = position + start;
      const endHighlight = Math.min(position + start + end, position + text.length);
      decorations.push(
        Decoration.inline(beginHighlight, endHighlight, {
          class: "highlight-red",
        })
      );
      start = 0;
      end -= endHighlight - beginHighlight;
    }
  });

  return DecorationSet.create(doc, decorations);
}

interface HighlighterState {
  start: number;
  end: number;
  decorations: DecorationSet;
}

const HighlighterName = "error-highlighter";
const ErrorHighlighter = Extension.create({
  name: HighlighterName,

  addCommands() {
    return {
      setErrorHighlight: (start: number, end: number) => {
        return (props: any) => {
          props.tr.setMeta(HighlighterName, { start, end });
          props.dispatch(props.tr);
          return true;
        };
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin<HighlighterState>({
        key: new PluginKey(HighlighterName),
        state: {
          init() {
            return {
              start: -1,
              end: -1,
              decorations: DecorationSet.empty,
            };
          },
          apply(transaction, oldState) {
            const start = transaction.getMeta(HighlighterName)?.start;
            const end = transaction.getMeta(HighlighterName)?.end;

            if (start !== undefined && end !== undefined) {
              return {
                start,
                end,
                decorations: generateHighlights(transaction.doc, start, end),
              };
            } else {
              return oldState;
            }
          },
        },
        props: {
          decorations(state) {
            return (this as any).getState(state).decorations;
          },
        },
      }),
    ];
  },
});
export default ErrorHighlighter;
