import useDebouncedCallback from "@/../util/useDebouncedCallback";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { COMPONENT_MODAL_TEMPLATE_MODE_REGEX } from "@shared/lib/components";
import { ComponentType, IFListWithCountComponent } from "@shared/types/Component";
import { useEffect, useMemo, useState } from "react";
import { ValueType } from "react-select";
import http, { API } from "../../../http/index";
import { useProjectContext } from "../../../views/Project/state/useProjectState";
import useFolderSelect, { Folder } from "../../FolderSelect/useFolderSelect";
import QuickCategoriesTitle from "../QuickCategoriesTitle";
import { ActualComponentInterface, CompVariants, ComponentInterface, SelectedCompVariants } from "../types";
import { useComponentCategories } from "./useComponentCategories";

type Suggestion = ComponentInterface & {
  suggestionInfo?: {
    _id: string;
    match: number;
  };
};

type OptionGroupOption = {
  value?: string;
  label?: string;
  isMenu?: boolean;
  categories?: string[];
  data?: IFListWithCountComponent["instances"][0];
  suggestionInfo?: { _id: string; match: number };
};

interface OptionGroup {
  label: React.ReactElement | string;
  options: OptionGroupOption[];
}

interface ComponentOption {
  value: string;
  label: string;
  type: "standard" | "template";
  data: IFListWithCountComponent["instances"][0];
}

export type ComponentInputOptions = OptionGroup[] | ComponentOption[];

interface Props {
  multiSelectedIds: string[];
  currentComp?: ActualComponentInterface;
  isSwap: boolean;
}

export interface ComponentInputProps {
  comps: IFListWithCountComponent[];
  searchValue: string;
  setSearchValue: (q: string) => void;
  folders: Folder[];
  selectedFolder: Folder;
  setSelectedFolder: (q: Folder) => void;
  selectedComp: ComponentInterface | null;
  selectedCompId: string | null;
  controlledKey: string;
  onCategoryClick: (category: string) => void;
  handleChange: (e: {
    value: string;
    label: string;
    __isNew__?: boolean;
    suggestionInfo?: { _id: string; match: string };
  }) => void;
  handleInputChange: (q: string) => void;
  newCompName: string;
  compVariants: CompVariants[];
  selectedCompVariants: SelectedCompVariants;
  loadingSelectedCompData: boolean;
  validateAbilityCreateComp: (input: string) => boolean;
  options: ComponentInputOptions;
  value: ValueType;
}

export const useComponentInput = (props: Props): ComponentInputProps => {
  const { multiSelectedIds, currentComp, isSwap } = props;
  const [searchValue, setSearchValue] = useState<string>("");
  const [newCompName, setNewCompName] = useState<string>("");
  const [comps, setComps] = useState<IFListWithCountComponent[]>([]);
  const [selectedComp, setSelectedComp] = useState<ComponentInterface | null>(null);
  const [selectedCompId, setSelectedCompId] = useState<string | null>(null);
  const [suggestionsFetched, setSuggestionsFetched] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [options, setOptions] = useState<ComponentInputOptions>([]);
  const [value, setValue] = useState<ValueType>([]);

  const [selectedCompVariants, setSelectedCompVariants] = useState<SelectedCompVariants>({
    inUse: [],
    notInUse: [],
  });
  const [loadingSelectedCompData, setLoadingSelectedCompData] = useState<boolean>(true);
  const [compVariants, setCompVariants] = useState<CompVariants[]>([]);

  const userHasDefaultComponentFolder = userHasResourcePermission("component_folder:comment", "component_folder_no_id");

  const {
    doc: [doc],
  } = useProjectContext();

  const { folders, selectedFolder, setSelectedFolder } = useFolderSelect({
    useSampleData: doc?.isSample,
    useLocalStorage: true,
    disableDefaultFolder: !userHasDefaultComponentFolder,
  });

  const { categories, controlledKey, onCategoryClick } = useComponentCategories({
    searchValue: [searchValue, setSearchValue],
    selectedFolderId: selectedFolder?._id,
    newCompName,
    selectedCompId,
  });

  const existingComponentNames = useMemo(() => {
    return comps.reduce((acc, curr) => {
      acc[curr.name] = true;
      return acc;
    }, {});
  }, [comps]);
  const handleInputChange = (inputValue: string) => {
    if (inputValue === "") {
      setValue(null);
    }

    setSearchValue(inputValue);
    loadOptions({ inputValue });
  };

  const fetchSelectedComp = async (comp_id: string) => {
    try {
      const { url } = API.ws_comp.get.ws_comp;
      const { data: comp } = await http.get(url(comp_id));
      return comp;
    } catch (error) {
      console.error("in allcomps.jsx: ", error.message);
    }
  };

  const fetchSelectedCompVariants = async (comp_id) => {
    try {
      const { url } = API.ws_comp.get.variants;
      const { data: variants } = await http.get(url(comp_id));
      return variants;
    } catch (err) {
      console.error("error fetching variants in componentmodal.jsx: ", err);
    }
  };
  const fetchSuggestions = async () => {
    try {
      const { url } = API.ws_comp.get.searchSuggestions;
      const { data } = await http.get(url(currentComp?._id, currentComp?.doc_ID));
      return data;
    } catch (error) {
      console.error("Error fetching suggestions in componentModal: ", error);
      return [];
    }
  };

  const fetchCompVariants = async () => {
    if (!currentComp) return;
    try {
      const { url } = API.variant.get.getVariantsForComp;
      const { data } = await http.get(url(currentComp._id));
      setCompVariants(data.variants);
    } catch (error) {
      console.error("Error fetching variants in componentModal: ", error);
    }
  };

  // Includes a folderOverride in the case that the folder is updated and we need
  // to load new options without waiting for state to update
  type FetchOptionsOptions = { inputValue: string; folderOverride?: Folder };

  const fetchOptions = async (options: FetchOptionsOptions) => {
    let value: string = options.inputValue;
    let componentType: ComponentType | undefined = undefined;

    if (COMPONENT_MODAL_TEMPLATE_MODE_REGEX.test(options.inputValue)) {
      // cut out the tokenized part of the input
      value = options.inputValue.split(COMPONENT_MODAL_TEMPLATE_MODE_REGEX)[1].trim();
      // limit results to template components
      componentType = "template";
    }

    try {
      const { url, body } = API.ws_comp.post.page;
      const shouldUseComponentNameQuery = categories.length > 0;
      const {
        data: { components },
      } = await http.post<{ components: IFListWithCountComponent[] }>(
        url,
        body({
          filter: {
            ...(shouldUseComponentNameQuery ? { name: value } : { search: value }),
            folder_id: options.folderOverride?._id || selectedFolder._id || undefined,
            componentType,
            // don't include sample components in non-sample projects
            isSample: doc ? doc.isSample : false,
          },
        }),
        {
          params: {
            page: 0,
            limit: 50,
          },
        }
      );

      let suggestionData = suggestions;

      // Only want to fetch suggestions once
      const shouldFetchSuggestions = !suggestionsFetched && !isSwap && currentComp;
      if (shouldFetchSuggestions) {
        suggestionData = await fetchSuggestions();
        setSuggestions(suggestionData);
        setSuggestionsFetched(true);
      }
      setComps(components);
      if (options.inputValue?.length) return formatOptions(components, [], options.inputValue);
      else return formatOptions(components, suggestionData, options.inputValue);
    } catch (error) {
      console.error("Error fetching components in componentModal: ", error);
      throw error;
    }
  };

  const loadOptions = useDebouncedCallback<FetchOptionsOptions>(
    (options: FetchOptionsOptions) => {
      fetchOptions(options).then((options) => {
        setOptions(options);
      });
    },
    300,
    [selectedFolder, suggestions, suggestionsFetched, controlledKey, categories]
  );

  useEffect(() => {
    loadOptions({ inputValue: searchValue });
  }, [selectedFolder]);

  // If there are groups or matches, will return OptionGroup[]
  // If there are no groups and no matches, will return ComponentOption[]
  const formatOptions = (
    components: IFListWithCountComponent[],
    suggestions: Suggestion[],
    inputValue: string
  ): ComponentInputOptions => {
    const componentSuggestions = suggestions.filter(
      (obj) => !selectedFolder?._id || obj.folder_id === selectedFolder?._id
    );
    const componentOptions: ComponentOption[] = components
      .filter((c) => c.instances.length > 0)
      .map((obj) => {
        return {
          value: obj._id,
          label: obj.name,
          type: obj.type,
          data: obj.instances[0],
        };
      })
      .filter((option) => {
        const optionCategories = option?.label?.split("/") || [];
        if (categories.length > 0) {
          const categoryIndex = categories.length - 1;
          const lastCategoryMatch = optionCategories[categoryIndex] === categories[categoryIndex];
          return lastCategoryMatch;
        }

        return true;
      });

    const componentCategoryMap = componentOptions.reduce((acc, curr) => {
      if (!curr) return acc;
      if (!curr.label.includes(inputValue)) return acc;
      // "Button/Text" -> ["Button"]; last portion is the component name, the previous parts are the categories
      const compCategories = curr.label.split("/").slice(0, -1);

      // Build component option category map based on the current level of category nesting in used
      let currentLevel = acc;
      const start = categories.length > 0 ? categories.length - 1 : 0;
      compCategories.slice(start).forEach((cat) => {
        currentLevel[cat] ??= {};
        currentLevel = currentLevel[cat];
      });
      return acc;
    }, {});

    const componentCategories: string[] =
      categories.length > 0
        ? Object.keys(componentCategoryMap[categories[categories.length - 1]] || {})
        : Object.keys(componentCategoryMap);

    const optionGroups: OptionGroup[] = [];

    if (componentSuggestions?.length) {
      const suggestionOptions = componentSuggestions
        .filter((obj) => obj.instances.length > 0)
        .map((obj) => {
          return {
            value: obj._id,
            label: obj.name,
            type: obj.type,
            suggestionInfo: obj?.suggestionInfo,
            data: obj.instances[0],
          };
        });

      const options = suggestionOptions.map((o) => {
        const result: OptionGroupOption = {
          value: o.value,
          label: o.label,
          data: o.data as any,
          suggestionInfo: o.suggestionInfo,
        };
        return result;
      });

      optionGroups.push({ label: "SUGGESTED", options });
    }
    if (componentCategories.length > 0) {
      optionGroups.push({
        label: QuickCategoriesTitle,
        options: [{ isMenu: true, categories: componentCategories }],
      });
    }
    if (optionGroups.length > 0) {
      optionGroups.push({ label: "ALL", options: componentOptions });
      return optionGroups;
    }
    return componentOptions;
  };

  const handleChange = async (option: {
    value: string;
    label: string;
    __isNew__?: boolean;
    suggestionInfo?: { _id: string; match: string };
  }) => {
    // Not sure how this can occur, but it appears that on occasion
    // users run into the issue of `option.value` being `null`, leading to
    // a crash. To prevent that from happening, if `option.value` is not truthy,
    // we return and clear the input.
    if (!option?.value) {
      setLoadingSelectedCompData(false);
      setSelectedCompId(null);
      setCompVariants([]);
      setNewCompName("");
      setSelectedComp(null);
      setSelectedCompVariants({ inUse: [], notInUse: [] });
      setValue(null);
      return;
    }

    if (option.__isNew__) {
      setNewCompName(option.value);
      setSelectedCompId(null);
      fetchCompVariants();

      // Remove the "Create xxx" label
      const match = option.label.match(/"([^"]*(?:""[^"]*)*)"/);
      const newName = match && match[1] ? match[1] : option.label;

      setValue({
        ...option,
        label: newName,
      });
      return;
    }

    setValue(option);
    setSearchValue("");
    setLoadingSelectedCompData(true);
    setSelectedCompId(option.value);
    window?.analytics?.track("Component Selected in Component Dropdown", {
      ws_comp: option.value,
    });

    const [comp, variants] = await Promise.all([
      fetchSelectedComp(option.value),
      fetchSelectedCompVariants(option.value),
    ]);

    setSelectedComp(comp);
    setSelectedCompVariants(variants);
    setLoadingSelectedCompData(false);

    if (option?.suggestionInfo) {
      window?.analytics?.track("Component Suggestion Selected in Component Dropdown", {
        suggestion_id: option?.suggestionInfo?._id,
        ws_comp: option?.value,
        rating: option?.suggestionInfo?.match,
      });
    }
    setNewCompName("");
  };

  const userHasEditAccessToFolder = userHasResourcePermission(
    "component_folder:edit",
    selectedFolder._id === "" ? "component_folder_no_id" : selectedFolder._id
  );

  const validateAbilityCreateComp = (inputValue: string) =>
    userHasEditAccessToFolder &&
    // multi-select is not on
    (!multiSelectedIds || multiSelectedIds.length === 0) &&
    // not swapping a component
    !isSwap &&
    // input is not empty
    inputValue.length > 0 &&
    // the name does not already exist
    !existingComponentNames[inputValue] &&
    // the name doesn't end in a slash (not sure why it was implemented this way)
    (inputValue.split("/").pop() || "")?.length > 0 &&
    !COMPONENT_MODAL_TEMPLATE_MODE_REGEX.test(inputValue);

  const setSelectedFolderWithLoadOptions: typeof setSelectedFolder = (folder: Folder) => {
    setSelectedFolder(folder);
    loadOptions({ inputValue: searchValue, folderOverride: folder });
  };

  return {
    comps,
    searchValue,
    setSearchValue,
    controlledKey,
    selectedCompId,
    selectedComp,
    onCategoryClick,
    newCompName,
    folders,
    selectedFolder,
    setSelectedFolder: setSelectedFolderWithLoadOptions,
    selectedCompVariants,
    loadingSelectedCompData,
    compVariants,
    handleChange,
    handleInputChange,
    validateAbilityCreateComp,
    options,
    value,
  };
};
