import { useAtomValue } from "jotai";
import { selectedBlockIdsAtom, selectedTextItemIdsAtom, useSelectionStateUpdate } from "../stores/SelectionStore";

function ProjectNav() {
  // Here is an example usage of consuming and updating selection state.
  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);
  const selectedBlockIds = useAtomValue(selectedBlockIdsAtom);
  const { addTextItemIds, removeTextItemIds, addBlockIds, removeBlockIds } = useSelectionStateUpdate();

  const blockElements = ["1", "2"].map((blockId) => (
    <div
      key={blockId}
      style={{
        textDecoration: selectedBlockIds.includes(blockId) ? "underline" : "none",
        cursor: "pointer",
      }}
      onClick={() => {
        if (selectedBlockIds.includes(blockId)) {
          removeBlockIds([blockId]);
        } else {
          addBlockIds([blockId]);
        }
      }}
    >
      block{blockId}
    </div>
  ));

  const textItemElements = ["1", "2", "3", "4", "5"].map((textItemId) => (
    <div
      key={textItemId}
      style={{
        textDecoration: selectedTextItemIds.includes(textItemId) ? "underline" : "none",
        cursor: "pointer",
      }}
      onClick={() => {
        if (selectedTextItemIds.includes(textItemId)) {
          removeTextItemIds([textItemId]);
        } else {
          addTextItemIds([textItemId]);
        }
      }}
    >
      textItem{textItemId}
    </div>
  ));

  return (
    <div>
      <h1>ProjectNav</h1>
      {blockElements}
      {textItemElements}
    </div>
  );
}

export { ProjectNav };
