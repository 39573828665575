import { useAtomValue } from "jotai";
import { selectedSidebarPanelAtom, useSelectionStateUpdate } from "../stores/SelectionStore";

function Sidebar() {
  // Here is an example usage of consuming and updating selection state.
  const sidebarPanel = useAtomValue(selectedSidebarPanelAtom);
  const { updateSidebarPanel } = useSelectionStateUpdate();

  return (
    <div>
      <h1>Sidebar</h1>
      <h2>Panel: {sidebarPanel || ""}</h2>
      <button onClick={() => updateSidebarPanel("EDIT")}>Edit</button>
      <button onClick={() => updateSidebarPanel("ACTIVITY")}>Activity</button>
      <button onClick={() => updateSidebarPanel(null)}>None</button>
    </div>
  );
}

export { Sidebar };
