import React, { useMemo } from "react";
import { useHasProjectFeatureFlag } from "../../hooks/useHasProjectFeatureFlag";
import RichTextRender from "../RichTextRender";
import VariableSpan from "../variable-interpolations/VariableSpan";
import style from "./style.module.css";

/**
 * Used to render actualcomponents in the list views of both
 * /doc and /components pages. Supports:
 * - rendering base text with or without variables
 * - rendering variant text (if a frameVariant is passed)
 */
const TextItem = (props) => {
  const { textItem, frameVariant } = props;

  const projectHasRichTextEnabled = useHasProjectFeatureFlag("rich_text");

  // Should always show rich text when on workspace components page
  // Should always show rich text displaying a component
  const isRichTextEnabled = textItem.ws_comp || projectHasRichTextEnabled;

  const { text, variables, richText } = useMemo(() => {
    if (!frameVariant) {
      return {
        text: textItem.text,
        variables: textItem.variables,
        richText: textItem.rich_text,
      };
    }

    const variantIndex = textItem.variants.findIndex((variant) => variant.variantId === frameVariant.id);

    if (variantIndex !== -1 && textItem.variants[variantIndex].text) {
      return {
        text: textItem.variants[variantIndex].text,
        variables: textItem.variants[variantIndex].variables,
        richText: textItem.variants[variantIndex].rich_text,
      };
    }

    return {
      text: textItem.text,
      variables: textItem.variables,
      richText: textItem.rich_text,
    };
  }, [textItem, frameVariant]);

  // Attaching a ws_component doesn't update the textItem's rich_text yet
  if (richText && isRichTextEnabled) {
    return (
      <RichTextRender
        richText={richText}
        renderProps={{ className: style.richTextContainer }}
        highlightBrackets={props.highlightBrackets}
      />
    );
  }

  return <VariableSpan text={text} variables={variables} highlightBrackets={props.highlightBrackets} />;
};

export default TextItem;
