import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  spacePx?: number;
  spaceLeftPx?: number;
  spaceRightPx?: number;
  sizePx?: number;
}

export function SeparatorDot(props: IProps) {
  const { spacePx, spaceLeftPx, spaceRightPx, sizePx, ...rest } = props;

  const inlineStyles: React.CSSProperties | undefined = React.useMemo(() => {
    const styles: React.CSSProperties = props.style || {};

    if (spacePx) {
      styles.marginLeft = `${spacePx}px`;
      styles.marginRight = `${spacePx}px`;
    }

    if (spaceLeftPx) {
      styles.marginLeft = `${spaceLeftPx}px`;
    }

    if (spaceRightPx) {
      styles.marginRight = `${spaceRightPx}px`;
    }

    if (sizePx) {
      styles.width = `${sizePx}px`;
      styles.height = `${sizePx}px`;
    }

    return styles;
  }, [props.style, sizePx, spacePx]);

  return (
    <div
      {...rest}
      style={inlineStyles}
      className={classNames(style.SeparatorDotWrapper, props.className)}
      data-testid="separator-dot"
    />
  );
}

export default SeparatorDot;
