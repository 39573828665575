import CodeIcon from "@mui/icons-material/Code";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderIcon from "@mui/icons-material/FolderOpenOutlined";
import classNames from "classnames";
import React from "react";
import waveEmoji from "../../../../assets/wave_emoji.png";
import { IMPORT_MODAL_QUERY_STRING } from "../../../../views/Components/index";
import LockedFolderIcon from "../../../icons/LockedFolderIcon";
import BlueFigmaIcon from "../BlueFigmaIcon";
import style from "./style.module.css";

interface TitleProps {
  folder: { _id: string; name: string; invite_only: boolean } | null;
}
const Title = ({ folder }: TitleProps) => (
  <>
    Start a new project{folder ? " in " : ""}
    {folder?.name && (
      <div className={style.folderIconWrapper}>
        {!folder.invite_only ? <FolderIcon className={style.icon} /> : <LockedFolderIcon className={style.icon} />}
        <div>{folder?.name}</div>
      </div>
    )}
  </>
);
interface BodyProps {
  isFirstProject: boolean;
  onDraftNewProject: () => void;
  onImportExistingFile: () => void;
}

function Body(props: BodyProps) {
  const { isFirstProject, onDraftNewProject, onImportExistingFile } = props;
  return (
    <div className={style.container}>
      {isFirstProject && (
        <div className={style.guide}>
          <img className={style.emoji} src={waveEmoji} />
          <div>
            Looks like you’re starting your first Ditto project! We’re so glad you’re here.{" "}
            <a href="https://www.dittowords.com/docs/what-is-ditto" target="_blank">
              Help on getting started
            </a>
          </div>
        </div>
      )}

      <div className={style.buttons}>
        <div className={style.importButton} onClick={onDraftNewProject}>
          <div className={classNames(style.iconWrapper, style.draft)}>
            <DescriptionOutlinedIcon className={style.icon} />
          </div>
          <span className={style.title}>Start from scratch</span>
          <p>You can always connect to design files or development later.</p>
        </div>

        <div data-testid="import-file-button" className={style.importButton} onClick={onImportExistingFile}>
          <div className={classNames(style.iconWrapper, style.figma)}>
            <BlueFigmaIcon />
          </div>
          <span className={style.title}>Import a Figma file</span>
          <p>
            Start with text in an existing
            <br /> Figma mockup.
          </p>
        </div>
      </div>

      <a className={style.importSection} href={`/components?${IMPORT_MODAL_QUERY_STRING}=true`}>
        <CodeIcon className={style.icon} />
        <span>
          Store copy in spreadsheets or strings files in your codebase? Get started by{" "}
          <strong>importing them into Ditto {"->"}</strong>
        </span>
      </a>
    </div>
  );
}

const Footer = () => null;
const WouldYouLikeTo = ({ children }: { children: React.ReactNode }) => <>{children}</>;
WouldYouLikeTo.Title = Title;
WouldYouLikeTo.Body = Body;
WouldYouLikeTo.Footer = Footer;

export default WouldYouLikeTo;
