import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import PeopleIcon from "@mui/icons-material/People";
import classnames from "classnames";
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";
import { routes } from "../../defs";
import LockedFolderIcon from "../icons/LockedFolderIcon";
import style from "./style.module.css";

const FolderCard = ({ folder }) => {
  const history = useHistory();
  const { _id: folderId, name, doc_ids, invite_only, access_count } = folder;
  const numberOfProjects = doc_ids.length;

  const goToFolder = (id) => {
    history.push(routes.nonNavRoutes.folder.getPath(id));
  };

  return (
    <Droppable droppableId={folderId} type="PROJECT">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={classnames({
            [style.responsiveCol]: true,
            [style.activeDrop]: false,
          })}
        >
          <div
            className={classnames({
              [style.folderCard]: true,
              [style.regular]: true,
              [style.activeDrop]: snapshot.isDraggingOver,
            })}
            onClick={() => goToFolder(folderId)}
          >
            <div className={style.folderLabel}>
              <div className={style.folderIconWrapper}>
                {invite_only ? (
                  <LockedFolderIcon className={style.icon} />
                ) : (
                  <FolderOpenOutlinedIcon className={style.icon} />
                )}
              </div>
              <div className={style.folderName}>
                <h3>{name}</h3>
                <div className={style.numProjects}>{`${numberOfProjects} project${
                  numberOfProjects !== 1 ? "s" : ""
                }`}</div>
              </div>
              <div style={{ display: "none" }}>{provided.placeholder}</div>
            </div>

            {invite_only && (
              <div className={style.inviteOnlyInfo}>
                <PeopleIcon className={style.icon} />
                {access_count}
              </div>
            )}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default FolderCard;
