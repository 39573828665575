import ErrorFallback from "@/components/ErrorFallback";
import http, { API } from "@/http";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { IFRole, IFUser } from "@shared/types/User";
import { IFWorkspace } from "@shared/types/Workspace";
import logger from "@shared/utils/logger";
import { RefObject, createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useAuthenticatedAuth } from "./AuthenticatedAuthContext";

type Job = "WRITER" | "DESIGN" | "ENGINEER" | "PRODUCT" | "MARKETING" | "LEGAL" | "OTHER";

type OnboardingState = "workspace" | "user" | "finished";

// there are additional properties on `user` that can be inspected
// at runtime but are not currently included in this interface
export interface User {
  _id: string;
  name: string;
  email: string;
  figmaAuth: {
    token: string;
  };
  finishedDittoOverview: boolean;
  "https://saydit.to/user_metadata": {
    hasFigmaPluginLogin: boolean;
    hasWebAppLogin: boolean;
  };
  isFigmaEditor: boolean;
  job: Job;
  onboardingState: OnboardingState;
  picture: string;
  role: IFRole;
  userId: string;
  workspaceId: string;
  permissionGroups: string[];
  secondaryUserIds: string[];
  subscriptionSettings: {
    productUpdates: boolean;
    appNotifs: boolean;
  };
  latestLegalAgreement: Date | null;
  createdAt: Date;
}

export type WorkspacePlan = "free" | "trial" | "team" | "growth" | "enterprise";

type NotificationCallback = (wsCompId: string, commentThreadId?: string) => void;

export interface WorkspaceContextType {
  users: IFUser[];
  fetchWorkspaceUsers: () => Promise<void>;
  setWorkspaceUsers: (users: IFUser[]) => void;
  workspaceInfo: IFWorkspace;
  updateWorkspaceInfo: (workspaceInfo: IFWorkspace | ((workspaceInfo: IFWorkspace) => IFWorkspace)) => void;
  reloadWorkspace: () => Promise<void>;
  globalNotificationCallbacks?: RefObject<NotificationCallback[]>;
  registerCallback?: (callback: NotificationCallback) => void;
  deregisterCallback?: (callback: NotificationCallback) => void;
}

export const WorkspaceContext = createContext({} as WorkspaceContextType);

export const useWorkspace = () => useContext(WorkspaceContext);

/**
 * This is a special hook that is used to get the WorkspaceContext
 * in places where it could potentially be null. YOU PROBABLY DON'T WANT TO USE THIS
 * UNLESS YOU KNOW WHAT YOU'RE DOING. most likely you were looking for `useWorkspace`.
 * @returns WorkspaceContextType | null
 */
export const UNSAFE_useWorkspace: () => WorkspaceContextType | null = () => useContext(WorkspaceContext);

export function WorkspaceProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuthenticatedAuth();
  const [workspace, setWorkspace] = useState<IFWorkspace | null>(null);
  const [workspaceUsers, setWorkspaceUsers] = useState<IFUser[]>([]);
  const globalNotificationCallbacks = useRef<NotificationCallback[]>([]);

  async function reloadWorkspace() {
    try {
      const { url } = API.api.get.workspace;
      const response = await http.get(url("current"));
      setWorkspace(response.data.workspaceInfo);
    } catch (err) {
      logger.error("Error fetching workspace", {}, err);
    }
  }

  async function reloadWorkspaceUsers() {
    try {
      const { url } = API.workspace.get.users;
      const response = await http.get(url);
      // This is needed so that mentions can use the id field. We shoud refactor this to user userId everywhere
      response.data.forEach((user) => {
        user.id = user.userId;
      });
      setWorkspaceUsers(response.data);
    } catch (err) {
      logger.error("Error fetching workspace users", {}, err);
    }
  }

  // Initialize workspace
  useEffect(function loadWorkspace() {
    reloadWorkspaceUsers();
    reloadWorkspace();
  }, []);

  useEffect(
    function updateSentryWorkspaceContext() {
      if (workspace) {
        const { _id, plan } = workspace;
        Sentry.setContext("workspace", workspace);
        Sentry.setTag("workspace._id", _id);
        Sentry.setTag("workspace.plan", plan);
      }
    },
    [workspace]
  );

  const onError = useCallback(
    (error) => {
      console.error(error);
      window.analytics.track("App Crash", {
        userId: user.userId,
        workspaceId: workspace?._id,
        user_name: user.name,
        plan: workspace?.plan,
        workspace_name: workspace?.name,
        workspace_id: workspace?._id,
        ts: Date.now() - 5000,
        datadogRUMInternalContext: datadogRum.getInternalContext(Date.now()),
      });
    },
    [workspace, user]
  );

  if (!workspace) {
    return <></>;
  }

  const workspaceContext: WorkspaceContextType = {
    users: workspaceUsers,
    fetchWorkspaceUsers: reloadWorkspaceUsers,
    setWorkspaceUsers,
    workspaceInfo: workspace,
    updateWorkspaceInfo: setWorkspace,
    reloadWorkspace,
    globalNotificationCallbacks,
    registerCallback: (callback) => {
      globalNotificationCallbacks.current.push(callback);
    },
    deregisterCallback: (callback) => {
      globalNotificationCallbacks.current = globalNotificationCallbacks.current.filter((cb) => cb !== callback);
    },
  };

  return (
    <Sentry.ErrorBoundary
      fallback={ErrorFallback}
      onError={onError}
      beforeCapture={(scope) => {
        scope.setTag("componentName", "App");
      }}
    >
      <WorkspaceContext.Provider value={workspaceContext}>{children}</WorkspaceContext.Provider>
    </Sentry.ErrorBoundary>
  );
}
