import ErrorHighlighter from "@shared/utils/tiptap/ErrorHighlighterExtension";
import getBaseEditorExtensions from "@shared/utils/tiptap/getBaseEditorExtensions";
import { TemplateComponentHighlighter } from "@shared/utils/tiptap/templates/TemplateComponentHighlighter";
import { TemplateHighlightNode } from "@shared/utils/tiptap/templates/TemplateHighlightNode";
import History from "@tiptap/extension-history";
import { Placeholder } from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import { Extensions } from "@tiptap/react";
import DevTools from "../views/Project/components/GroupDraft/editor/extensions/DevTools";
import { DisableRichText } from "./DisableRichText";
import SuberscriptKeyboardShortcuts from "./SuberscriptKeyboardShortcuts";
import { Variable as VariableNode } from "./VariableNode";

const getExtensions = (options: { isRichTextEnabled?: boolean; highlightBrackets?: boolean; placeholder?: string }) => {
  const extensions: Extensions = [
    ...getBaseEditorExtensions(VariableNode),
    History,
    Typography,
    Placeholder.configure({
      placeholder: options.placeholder || "No text.",
      showOnlyWhenEditable: false,
    }),
    SuberscriptKeyboardShortcuts,
    ErrorHighlighter,
  ];

  // ProseMirror devtools are helpful in local development
  if (process.env.IS_LOCAL === "true") {
    extensions.push(DevTools);
  }

  // We don't always want rich text to be enabled
  if (!options.isRichTextEnabled) {
    extensions.push(DisableRichText);
  }

  // A mode for highlighting brackets is enabled when editing a template
  // component or rendering its content
  if (options.highlightBrackets) {
    // a custom extension responsible for adding decorations that update live as
    // the text is modified, without modifying the underlying schema
    extensions.push(TemplateComponentHighlighter);
    // a custom mark responsible for determining how the brackets are rendered
    // in rich text contexts
    extensions.push(TemplateHighlightNode);
  }

  return extensions;
};

export { getExtensions };
