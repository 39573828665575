import { useWorkspace } from "@/store/workspaceContext";
import LockIcon from "@mui/icons-material/Lock";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { IFProjectSummary } from "@shared/types/http/project";
import { default as classNames, default as classnames } from "classnames";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ProjectCard from "../ProjectCard/ProjectCard";
import ButtonPrimary from "../button/buttonprimary";
import NewCard from "../card/newcard";
import FolderName from "./FolderName";
import style from "./style.module.css";

interface ProjectFolderProps {
  folder: {
    _id: string;
    name: string;
    invite_only: boolean;
  };
  uniqueFolderNames: string[];
  docs: IFProjectSummary[];
  openShareFolderModal: () => void;
  handleCreateNewProject: () => void;
  handleDeleteFolder: (folderId: string) => void;
  handleRenameFolder: (folderId: string, updatedName: string) => void;
  handleRemoveFromFolder: (folderId: string, docId: string) => void;
}
const ProjectFolder = ({
  folder,
  uniqueFolderNames,
  docs,
  openShareFolderModal,
  handleCreateNewProject,
  handleDeleteFolder,
  handleRenameFolder,
  handleRemoveFromFolder,
}: ProjectFolderProps) => {
  const history = useHistory();
  const { workspaceInfo } = useWorkspace();

  const isEditEnabled = userHasResourcePermission("project_folder:edit");

  const goBack = () => history.push("/projects");

  useEffect(() => {
    window?.analytics?.page();
  }, []);

  const isPermissionGroupsEnabled = workspaceInfo?.plan === "enterprise";

  return (
    <div className={style.main}>
      <div className={style.backBtn} onClick={goBack}>
        {"<- All Projects"}
      </div>
      <div className={style.titleBar}>
        <FolderName
          isEditEnabled={isEditEnabled}
          folder={folder}
          uniqueFolderNames={uniqueFolderNames}
          handleRenameFolder={handleRenameFolder}
          handleDeleteFolder={handleDeleteFolder}
        />
        <ButtonPrimary
          text={
            <>{!isPermissionGroupsEnabled && folder.invite_only && <LockIcon className={style.lockIcon} />} Share</>
          }
          onClick={openShareFolderModal}
        />
      </div>

      {docs.length === 0 && (
        <div className={style.noResultsMsg}>
          <div className={style.label}>This folder is empty</div>
          {isEditEnabled && (
            <div>
              <u onClick={handleCreateNewProject}>Start a new project</u>, or add projects by dragging and dropping from
              the main projects page.
            </div>
          )}
        </div>
      )}
      {docs.length > 0 && (
        <div className={classnames("row", style.allProjects)}>
          {isEditEnabled && <NewCard newProject={handleCreateNewProject} isFirstProject={false} />}
          {docs.map((project, index) => (
            <ProjectCard
              key={project._id}
              className={classNames(style.responsiveCol, style.fullCardHeight)}
              options={{
                hideFolder: true,
                canRemoveFromFolder: isEditEnabled,
                handleRemoveFromFolder,
              }}
              project={project}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectFolder;
