import TabbedMenu, { TabData } from "@/components/TabbedMenu/TabbedMenu";
import AccountCircleIcon from "@/components/icons/AccountCircleIcon";
import AccountCircleOutlineIcon from "@/components/icons/AccountCircleOutlineIcon";
import GroupsIcon from "@/components/icons/GroupsIcon";
import GroupsOutlineIcon from "@/components/icons/GroupsOutlineIcon";
import usePermissionGroups from "@/components/permissions/usePermissionGroups";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import Lock from "@mui/icons-material/Lock";
import LockOutlined from "@mui/icons-material/LockOutlined";
import PaymentOutlined from "@mui/icons-material/PaymentOutlined";
import { useDittoComponent } from "ditto-react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import BillingTab from "./components/BillingTab";
import PermissionGroupsTab from "./components/PermissionGroupsTab";
import UserTab from "./components/UserTab";
import WorkspaceTab from "./components/WorkspaceTab";
import * as style from "./style.module.css";

export default function SettingsPage() {
  const { workspaceInfo } = useWorkspace();
  const { user } = useAuthenticatedAuth();
  const isSelfServe = workspaceInfo?.plan !== "enterprise";
  const isAdmin = user.permissionGroups.groups.map((group) => group._id).includes("admin");
  const path = window.location.pathname.split("/")[1];

  const permissionGroupState = usePermissionGroups();
  const { permissionGroups } = permissionGroupState;

  const workspaceTabTitle = useDittoComponent({ componentId: "workspace" });
  const userTabTitle = useDittoComponent({ componentId: "user" });
  const permissionGroupsTabTitle = useDittoComponent({ componentId: "permission-groups" });
  const billingTabTitle = useDittoComponent({ componentId: "billing" });

  const settingsTabs = [
    {
      path: `/${path}/workspace`,
      title: workspaceTabTitle,
      icon: GroupsOutlineIcon,
      activeIcon: GroupsIcon,
    },
    {
      path: `/${path}/user`,
      title: userTabTitle,
      icon: AccountCircleOutlineIcon,
      activeIcon: AccountCircleIcon,
    },
    ...(workspaceInfo && (isSelfServe || isAdmin)
      ? [
          {
            path: `/${path}/permissions`,
            title: permissionGroupsTabTitle,
            icon: LockOutlined,
            activeIcon: Lock,
          },
        ]
      : []),
    ...(isAdmin && workspaceInfo && workspaceInfo?.plan !== "free" && workspaceInfo?.plan !== "enterprise"
      ? [
          {
            path: `/${path}/billing`,
            title: billingTabTitle,
            icon: PaymentOutlined,
          },
        ]
      : []),
  ] as TabData[];

  return (
    <div className={style.container}>
      <div className={style.pageTitle}>Settings</div>
      {workspaceInfo && (
        <div>
          <TabbedMenu tabs={settingsTabs} />
          <Switch>
            <Route path={`/${path}/workspace`} key="workspace">
              <WorkspaceTab permissionGroups={permissionGroups} />
            </Route>
            <Route path={`/${path}/user`} key="workspace">
              <UserTab permissionGroups={permissionGroups} />
            </Route>
            <Route path={`/${path}/permissions`} key="workspace">
              <PermissionGroupsTab premissionGroupState={permissionGroupState} />
            </Route>
            {isAdmin && workspaceInfo?.plan !== "free" && workspaceInfo?.plan !== "enterprise" && (
              <Route path={`/${path}/billing`} key="workspace">
                <BillingTab />
              </Route>
            )}
            <Route path={`/${path}/`} key="redirect">
              <WorkspaceTab permissionGroups={permissionGroups} />
            </Route>
          </Switch>
        </div>
      )}
    </div>
  );
}
