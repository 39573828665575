import CallSplitIcon from "@mui/icons-material/CallSplit";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import classnames from "classnames";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { scroller } from "react-scroll";
import TimeAgo from "react-timeago";
import { PANELS, routes } from "../../defs";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import EditDiff from "../editdiff/editdiff";
import InlineCommentThread from "../inlineCommentThread/inlineCommentThread";

import { ENTRY_TYPES } from "@shared/types/ActualChange";

import { DittoComponent } from "ditto-react";
import { ResolvedSuggestionActivityHeader } from "../comment-editor/SuggestionResolution";
import style from "./style.module.css";

// Eventually, move this type to @shared and bind it as source of truth
// for endpoint from which data is fetched
interface IChangeItem {
  doc_id: string | null;
  doc_name: string | null;
  user: string;
  entry_type: string;
  date_time: string;
  text_before: string | null;
  text_after: string | null;
  ws_comp: string | { _id: string; name: string } | null;
  comment_thread_id: string | { _id: string; suggestion: any; is_resolved: boolean; updatedAt: Date } | null;
  variantId: string | { _id: string; name: string } | null;
  component_name: string | null;
  variant_name: string | null;
  dupe_ws_comp_ids: string[] | null;
  status: string | null;
  data?: any;
}

interface IProps {
  changeHistory: IChangeItem[];
  setPanelState: (panelState: string) => void;
  workspaceUsers: {}[];
  setCommentState: (commentState: { isSelected: boolean; thread_id: string }) => void;
  selectComp: (compId: string, options?: { shouldResetSearch?: boolean }) => void;
  refreshLibraryHistory: () => void;
  updateCompResultComments: () => void;
  setShowCompError: (compError: boolean) => void;
  handleChangeItemClick: (changeItem: any) => void;
  onResolveSuggestion: () => void;
  handleCommentChangeClick: (compId: string, commentThreadId: string, showHistoryPanel: boolean) => void;
  emptyText?: string;
}

const variantIdIsPopulated = (
  variantId: IProps["changeHistory"][number]["variantId"]
): variantId is { _id: string; name: string } => {
  return Boolean(variantId && typeof variantId === "object" && "_id" in variantId);
};

const wsCompIsPopulated = (
  ws_comp: IProps["changeHistory"][number]["ws_comp"]
): ws_comp is { _id: string; name: string } => {
  return Boolean(ws_comp && typeof ws_comp === "object" && "_id" in ws_comp);
};

const commentThreadIsPopulated = (
  comment_thread_id: IProps["changeHistory"][number]["comment_thread_id"]
): comment_thread_id is {
  _id: string;
  suggestion: any;
  is_resolved: boolean;
  updatedAt: Date;
} => {
  return Boolean(comment_thread_id);
};

const ActivityColumn = (props: IProps) => {
  const {
    changeHistory,
    setPanelState,
    workspaceUsers,
    setCommentState,
    selectComp,
    refreshLibraryHistory,
    updateCompResultComments,
    setShowCompError,

    /**
     * This is a new method that I think is preferable moving forward to allow different
     * contexts to render the Activity Column while uniquely controlling how clicking the change items
     * is handled.
     */
    handleChangeItemClick,
    onResolveSuggestion,
    emptyText = "No changes.",
  } = props;

  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);
  const history = useHistory();
  const [activeCommentThreadId, setActiveCommentThreadId] = useState<string | null | undefined>();

  /**
   * Scrolls to html element id
   * @param {string} id
   */
  const handleScrollTo = (id: string, containerId = "projectContainer") => {
    let timeOut = 0;
    if (!document.getElementById(id)) {
      timeOut = 50;
    }

    setTimeout(() => {
      if (id) {
        scroller.scrollTo(id, {
          duration: 500,
          smooth: true,
          containerId,
          offset: -100,
        });
      }
    }, timeOut);
  };

  /**
   * Sets or releases active comment thread
   * @param {string} id comment thread ID
   */
  const handleCommentThreadClicked = (id) => {
    if (activeCommentThreadId === id) {
      setActiveCommentThreadId(null);
    } else setActiveCommentThreadId(id);
  };

  /**
   * Selects the thread
   *
   * Selects the comp, for use in the CompDetail
   *
   * Will either move you to the "EDIT" panel, or
   * keep you on the "INLINE-REPLY" (activity) panel if
   * it is an inline thread
   * @param {string} ws_comp_id
   * @param {string} comment_thread_id
   * @param {boolean} showHistoryPanel
   */
  const handleCommentChangeClick = (ws_comp_id, comment_thread_id, showHistoryPanel = true) => {
    if (!ws_comp_id) {
      setShowCompError(true);
    }
    if (showHistoryPanel) {
      checkDetailPanelChanges(() => {
        selectComp(ws_comp_id);
        setCommentState({ isSelected: true, thread_id: comment_thread_id });
        setPanelState(PANELS.comp_library.edit);

        if (props.handleCommentChangeClick) {
          props.handleCommentChangeClick(ws_comp_id, comment_thread_id, showHistoryPanel);
        }
      });
    } else {
      checkDetailPanelChanges(() => {
        selectComp(ws_comp_id);
        setCommentState({ isSelected: true, thread_id: comment_thread_id });
        setPanelState(PANELS.comp_library.inline_reply);

        if (props.handleCommentChangeClick) {
          props.handleCommentChangeClick(ws_comp_id, comment_thread_id, showHistoryPanel);
        }
      });
    }
  };

  /**
   * Opens up doc
   * @param {string} id
   * @param {string} type
   */
  const goToDoc = (id, type) => {
    if (type !== "delete") {
      history.push(routes.nonNavRoutes.project.getPath(id));
    }
  };

  /**
   * Navigates to components/:id
   * @param {string} ws_comp
   */
  const openWsComp = (ws_comp) => {
    if (!ws_comp) {
      setShowCompError(true);
      return;
    }
    selectComp(ws_comp, { shouldResetSearch: true });
  };

  function getChangeAction(type) {
    if (
      type === "edit" ||
      type === "dupes-edit" ||
      type === "text-apiID-edit" ||
      type === "frame-apiID-edit" ||
      type === "block-apiID-edit"
    ) {
      return "Edited ";
    } else if (type === "delete") {
      return "Deleted ";
    } else if (type === "import") {
      return "Imported ";
    } else if (type === "status" || type === "dupes-status") {
      return "Changed text status in  ";
    } else if (type === "sync") {
      return "Synced ";
    } else if (type === "post-comment") {
      return "Posted a new comment in ";
    } else if (type === "post-reply") {
      return "Replied to a comment in ";
    } else if (type === "dev-mode-on") {
      return "Turned on Developer Mode in ";
    }
  }

  if (changeHistory?.length !== 0) {
    return changeHistory.map((changeItem, index) => {
      const {
        doc_id,
        doc_name,
        user,
        entry_type,
        date_time,
        text_before,
        text_after,
        ws_comp,
        comment_thread_id,
        variantId,
        component_name,
        variant_name,
        dupe_ws_comp_ids,
        status,
        data = {},
      } = changeItem;

      const getVariantName = () => {
        const name = variantIdIsPopulated(variantId) ? variantId.name || variant_name : variant_name;
        return name || "[DELETED VARIANT]";
      };

      const getWsCompName = (returnDefault = true) => {
        const name = wsCompIsPopulated(ws_comp) ? ws_comp.name || component_name : component_name;

        if (!returnDefault) {
          return name;
        }

        return name || "[DELETED COMPONENT]";
      };

      if (entry_type === "variant-apiID-edit") {
        return (
          <div
            key={index}
            className={style.version}
            onClick={() => variantIdIsPopulated(variantId) && handleScrollTo(variantId._id, "variantContainer")}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <EditDiff
              text_before={text_before || ""}
              text_after={text_after || ""}
              key={ws_comp && typeof ws_comp === "object" && "_id" in ws_comp ? ws_comp._id : ""}
              isApiID={entry_type === "variant-apiID-edit"}
            />
            <div className={classnames([style.changeAndCountVariants, style.marginTop])}>
              {variantId && (
                <div
                  className={classnames([style.changeVariants], [style.variantChangeVariants], {
                    [style.cursorPointer]: variantId !== null,
                  })}
                >
                  <div className={style.variantNameWrapper}>
                    <span className={style.variantName}>
                      <CallSplitIcon className={style.icon} />
                      {getVariantName()}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      }
      if (entry_type === "variant-name-edit") {
        return (
          <div
            key={index}
            className={style.version}
            onClick={() => variantIdIsPopulated(variantId) && handleScrollTo(variantId._id, "variantContainer")}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>

            <div className={classnames([style.changeAndCountVariants, style.marginTop])}>
              <div className={style.comp}>
                Renamed{" "}
                <div className={style.variantNameWrapper}>
                  <span className={style.variantName}>
                    {" "}
                    <CallSplitIcon className={style.icon} />
                    {text_before || ""}
                  </span>
                </div>{" "}
                to{" "}
                <div className={style.variantNameWrapper}>
                  <span className={style.variantName}>
                    <CallSplitIcon className={style.icon} /> {text_after || ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }

      if (entry_type === ENTRY_TYPES.VARIANT_DESCRIPTION_EDIT) {
        const { variantName } = data;
        return (
          <div
            key={index}
            className={style.version}
            onClick={() => variantIdIsPopulated(variantId) && handleScrollTo(variantId._id, "variantContainer")}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>

            <div className={classnames([style.changeAndCountVariants, style.marginTop])}>
              <div className={style.comp}>
                Updated description for{" "}
                <div className={style.variantNameWrapper}>
                  <span className={style.variantName}>
                    {" "}
                    <CallSplitIcon className={style.icon} />
                    {variantName}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }

      if (entry_type === "variant-created") {
        return (
          <div
            key={index}
            className={style.version}
            onClick={() => {
              if (variantIdIsPopulated(variantId)) handleScrollTo(variantId._id, "variantContainer");
            }}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div className={style.comp}>
              Created{" "}
              {variantId && (
                <div className={style.variantNameWrapper}>
                  <span className={style.variantName}>
                    <CallSplitIcon className={style.icon} />
                    {getVariantName()}
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      }
      if (entry_type === "variant-deleted") {
        return (
          <div key={index} className={style.version} onClick={() => {}}>
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>

            <div className={style.comp}>
              Deleted{" "}
              <div className={style.variantNameWrapper}>
                <span className={style.variantName}>
                  <CallSplitIcon className={style.icon} /> {getVariantName()}
                </span>
              </div>
            </div>
          </div>
        );
      }
      if (entry_type === "ws-comp-status") {
        const { componentType } = data;
        const componentText = componentType === "template" ? "template component" : "component";

        return (
          <div
            key={index}
            className={style.version}
            onClick={() => wsCompIsPopulated(ws_comp) && openWsComp(ws_comp._id)}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>

            <Status status={status}>
              Marked {componentText} as <strong>{status}</strong>
            </Status>
          </div>
        );
      }
      if (
        entry_type === ENTRY_TYPES.WS_COMP_EDIT ||
        entry_type === ENTRY_TYPES.WS_COMP_EDIT_BULK ||
        entry_type === ENTRY_TYPES.WS_APIID_EDIT ||
        entry_type === ENTRY_TYPES.WS_APIID_EDIT_BULK
      ) {
        if (!ws_comp) return;
        return (
          <div
            key={index}
            className={style.version}
            onClick={() => wsCompIsPopulated(ws_comp) && openWsComp(ws_comp._id)}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <EditDiff
              text_before={text_before || ""}
              text_after={text_after || ""}
              rich_text_before={data?.rich_text_before}
              rich_text_after={data?.rich_text_after}
              key={wsCompIsPopulated(ws_comp) ? ws_comp._id : ""}
              showRichText={true}
              isApiID={entry_type === ENTRY_TYPES.WS_APIID_EDIT || entry_type === ENTRY_TYPES.WS_APIID_EDIT_BULK}
            />
            <div className={classnames([style.changeAndCountVariants, style.marginTop])}>
              {variantId && (
                <div className={style.variantNameWrapper}>
                  <span className={style.variantName}>
                    <CallSplitIcon className={style.icon} />
                    {getVariantName()}
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      }
      if (entry_type === "post-comment" && ws_comp) {
        return (
          <div
            key={index}
            className={style.version}
            onClick={() =>
              wsCompIsPopulated(ws_comp) &&
              history.push({
                pathname: `/components/${ws_comp && ws_comp._id}`,
                state: {
                  commentThreadId: comment_thread_id,
                  wsCompId: ws_comp && ws_comp._id,
                },
              })
            }
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div className={style.changeDetail}>
              Posted a new comment in the <span className={style.docName}>component library</span>
            </div>
          </div>
        );
      }
      if (entry_type === "post-reply" && ws_comp) {
        return (
          <div
            key={index}
            className={style.version}
            onClick={() =>
              wsCompIsPopulated(ws_comp) &&
              history.push({
                pathname: `/components/${ws_comp && ws_comp._id}`,
                state: {
                  commentThreadId: comment_thread_id,
                  wsCompId: ws_comp && ws_comp._id,
                },
              })
            }
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div className={style.changeDetail}>
              Replied to a comment in the <span className={style.docName}>component library</span>
            </div>
          </div>
        );
      }
      if (entry_type === "ws-comp-swap") {
        return (
          <div
            key={index}
            className={style.version}
            onClick={() => wsCompIsPopulated(ws_comp) && history.push("/components/" + ws_comp._id)}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div className={style.changeDetail}>Swapped a component</div>
          </div>
        );
      }
      if (entry_type === "ws-comp-delete-variant") {
        const { componentType = "standard" } = data;
        return (
          <div
            key={index}
            className={style.version}
            onClick={() => wsCompIsPopulated(ws_comp) && openWsComp(ws_comp._id)}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div className={style.changeDetail}>
              Removed{" "}
              <div className={style.variantNameWrapper}>
                <span className={style.variantName}>
                  <CallSplitIcon className={style.icon} />
                  {getVariantName()}
                </span>
              </div>
              from <ComponentLabel name={getWsCompName()} type={componentType} />
            </div>
          </div>
        );
      }
      if (entry_type === "ws-comp-add-variant") {
        const { componentType = "standard" } = data;
        return (
          <div
            key={index}
            className={style.version}
            onClick={() => wsCompIsPopulated(ws_comp) && openWsComp(ws_comp._id)}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div className={style.changeDetail}>
              <div className={style.marginBottom}>
                <EditDiff
                  text_before={text_before || ""}
                  text_after={text_after || ""}
                  rich_text_before={data?.rich_text_before}
                  rich_text_after={data?.rich_text_after}
                  showRichText={true}
                  key={wsCompIsPopulated(ws_comp) ? ws_comp._id : ""}
                />
              </div>
              Added{" "}
              <div className={style.variantNameWrapper}>
                <span className={style.variantName}>
                  <CallSplitIcon className={style.icon} />
                  {getVariantName()}
                </span>
              </div>
              to <ComponentLabel name={getWsCompName()} type={componentType} />
            </div>
          </div>
        );
      }
      if (entry_type === ENTRY_TYPES.WS_COMP_CREATION) {
        const { componentType } = data;

        return (
          <div
            key={index}
            className={style.version}
            onClick={() => wsCompIsPopulated(ws_comp) && openWsComp(ws_comp._id)}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div className={style.comp}>
              Created <ComponentLabel type={componentType} name={getWsCompName()} />
            </div>
          </div>
        );
      }
      if (entry_type === "ws-comp-deletion") {
        const { componentType = "standard" } = data;
        return (
          <div key={index} className={style.version} onClick={() => setShowCompError(true)}>
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div className={style.comp}>
              Deleted <ComponentLabel name={getWsCompName(false) || text_before || ""} type={componentType} />
            </div>
          </div>
        );
      }
      if (entry_type === "dupes-ws-comp-edit") {
        return (
          <div key={index} className={classnames([style.version, style.disabled])}>
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>

            <div className={style.changeDetail}>
              <div className={style.flexDistributed}>
                <span>Updated components via the API</span>
                <span className={style.count}>{dupe_ws_comp_ids?.length ?? "some"}</span>
              </div>
              <div className={classnames([style.changeAndCountVariants, style.marginTop])}>
                <div className={style.variantNameWrapper}>
                  <span className={style.variantName}>
                    <CallSplitIcon className={style.icon} />
                    {getVariantName()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (entry_type === "dupes-ws-comp-library-edit") {
        // TODO: someday support change items where only tags are edited
        if (!data.status) {
          return <React.Fragment key={index} />;
        }

        return (
          <div key={index} className={classnames([style.version, style.disabled])}>
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>

            <div className={style.changeDetail}>
              <div className={style.flexDistributed}>
                <Status status={data.status}>
                  Marked components as <strong>{data.status}</strong>
                </Status>
                <span className={style.count}>{dupe_ws_comp_ids?.length ?? "some"}</span>
              </div>
            </div>
          </div>
        );
      }
      if (
        [
          ENTRY_TYPES.DUPES_WS_COMP_CREATION,
          ENTRY_TYPES.DUPES_WS_COMP_VARIANT_UPDATE,
          ENTRY_TYPES.DUPES_WS_COMP_BASE_TEXT_UPDATE,
        ].includes(entry_type)
      ) {
        let action = "Imported";
        if (entry_type === ENTRY_TYPES.DUPES_WS_COMP_VARIANT_UPDATE) {
          // could include the name of the variant here
          action = `Updated ${data.variantName ?? "variant"} variant for`;
        } else if (entry_type === ENTRY_TYPES.DUPES_WS_COMP_BASE_TEXT_UPDATE) {
          action = "Updated";
        }

        let fileString = "a JSON file";
        if (data.format === "csv") {
          fileString = "a CSV file";
        } else if (data.format === "ios-strings") {
          fileString = "a .strings file";
        } else if (data.format === "xml") {
          fileString = "an XML file";
        }

        return (
          <div key={index} className={classnames([style.version, style.disabled])}>
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>

            <div className={style.changeDetail}>
              <div className={style.flexDistributed}>
                <span>
                  <DittoComponent
                    componentId="component-imports.change-item"
                    count={dupe_ws_comp_ids?.length ?? 0}
                    variables={{
                      pastTenseVerb: action,
                      componentCount: dupe_ws_comp_ids?.length || "some",
                      fileType: fileString,
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        );
      }
      if (entry_type === "dupes-ws-comp-detach-and-delete") {
        const user = changeItem.user;
        const { components, componentType = "standard" } = changeItem.data;
        if (!components) {
          return <React.Fragment key={index} />;
        }

        return (
          <div key={index} className={classnames([style.version, style.disabled])}>
            {user && (
              <div className={style.meta}>
                {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
              </div>
            )}
            {components.length === 1 && (
              <div className={style.comp}>
                Detached and deleted <ComponentLabel name={components[0].name} type={componentType} />
              </div>
            )}
            {components.length > 1 && (
              <div>
                Detached and deleted {components.length} component
                {components.length > 1 ? "s" : ""}
              </div>
            )}
          </div>
        );
      }

      if (entry_type === "dupes-ws-comp-move-to-folder") {
        const user = changeItem.user;
        const componentIds = changeItem.data.componentIds;
        const folderAfter = changeItem.data.folder.after;
        const folderBefore = changeItem.data.folder.before;

        if (!componentIds) {
          return <React.Fragment key={index} />;
        }

        const folderName = folderAfter?.name || folderBefore?.name || "";
        const preposition = folderAfter ? "to" : folderBefore ? "out of " : "";

        return (
          <FolderChangeItem
            key={index}
            user={user}
            date={date_time}
            onClick={() => handleChangeItemClick(changeItem)}
            text={`Moved ${componentIds.length} components ${preposition}`}
            folderName={folderName}
          />
        );
      }

      if (entry_type === "ws-comp-move-to-folder") {
        const componentType = data?.componentType || "standard";
        const componentName = data?.componentName || "";
        const folderAfter = data?.folder?.after;
        const folderBefore = data?.folder?.before;

        const folderName = folderAfter?.name || folderBefore?.name || "";
        const preposition = folderAfter ? "to" : folderBefore ? "out of " : "";

        return (
          <FolderChangeItem
            key={index}
            user={user}
            date={date_time}
            onClick={() => handleChangeItemClick(changeItem)}
            text={
              <span>
                Moved <ComponentLabel name={componentName} type={componentType} /> {preposition}
              </span>
            }
            folderName={folderName}
          />
        );
      }

      if (entry_type === "component-folder-creation") {
        const { name } = data.component_folder;

        return (
          <FolderChangeItem
            key={index}
            user={user}
            date={date_time}
            onClick={() => handleChangeItemClick(changeItem)}
            text="Created"
            folderName={name}
          />
        );
      }
      if (entry_type === "component-folder-apiID-edit") {
        return (
          <div key={index} className={style.version} onClick={() => handleChangeItemClick(changeItem)}>
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <EditDiff
              text_before={changeItem.data.component_folder_before.apiID || ""}
              text_after={changeItem.data.component_folder_after.apiID || ""}
              showRichText={true}
              isApiID={true}
            />
          </div>
        );
      }
      if (entry_type === "variant-move-to-folder") {
        const name = data?.variantName || "";
        const folderAfter = data?.folder?.after;
        const folderBefore = data?.folder?.before;

        const folderName = folderAfter?.name || folderBefore?.name || "";
        const preposition = folderAfter ? "to" : folderBefore ? "out of " : "";

        return (
          <FolderChangeItem
            key={index}
            user={user}
            date={date_time}
            onClick={() => handleChangeItemClick(changeItem)}
            text={
              <span>
                Moved <VariantLabel name={name} /> {preposition}
              </span>
            }
            folderName={folderName}
          />
        );
      }
      if (entry_type === "variant-folder-creation") {
        const { name } = data.variant_folder;

        return (
          <FolderChangeItem
            key={index}
            user={user}
            date={date_time}
            onClick={() => handleChangeItemClick(changeItem)}
            text="Created"
            folderName={name}
          />
        );
      }
      if (entry_type === "variant-folder-deletion") {
        const { name } = data.variant_folder;

        return (
          <FolderChangeItem
            key={index}
            user={user}
            date={date_time}
            onClick={() => handleChangeItemClick(changeItem)}
            text="Deleted"
            folderName={name}
          />
        );
      }
      if (entry_type === "variant-folder-update") {
        const { name: nameBefore } = data.variant_folder_before;
        const { name: nameAfter } = data.variant_folder_after;

        return (
          <div
            key={index}
            className={classnames([style.version, style.disabled])}
            onClick={() => handleChangeItemClick(changeItem)}
          >
            <User user={user} date={date_time} />
            <div className={style.folderTextContainer}>
              <span>Renamed</span>
              <span className={style.folderText}>
                <FolderIcon className={style.folderIcon} /> {nameBefore}
              </span>{" "}
              <span>to </span>
              <span className={style.folderText} style={{ marginTop: "4px" }}>
                <FolderIcon className={style.folderIcon} /> {nameAfter}
              </span>
            </div>
          </div>
        );
      }

      if (entry_type === "component-folder-deletion") {
        const { name } = data.component_folder;

        return (
          <FolderChangeItem
            key={index}
            user={user}
            date={date_time}
            onClick={() => handleChangeItemClick(changeItem)}
            text="Deleted"
            folderName={name}
          />
        );
      }
      if (entry_type === "component-folder-update") {
        const { name: nameBefore } = data.component_folder_before;
        const { name: nameAfter } = data.component_folder_after;

        return (
          <div key={index} className={classnames([style.version])} onClick={() => handleChangeItemClick(changeItem)}>
            <User user={user} date={date_time} />
            <div className={style.folderTextContainer}>
              <span>Renamed</span>
              <span className={style.folderText}>
                <FolderIcon className={style.folderIcon} /> {nameBefore}
              </span>{" "}
              <span>to </span>
              <span className={style.folderText} style={{ marginTop: "4px" }}>
                <FolderIcon className={style.folderIcon} /> {nameAfter}
              </span>
            </div>
          </div>
        );
      }

      if (entry_type === "inline-comment") {
        if (!commentThreadIsPopulated(comment_thread_id)) return <React.Fragment />;

        const isSuggestion = Boolean(comment_thread_id.suggestion);
        const isResolved = comment_thread_id.is_resolved;
        const isResolvedSuggestion = isSuggestion && isResolved;

        const wsCompId = wsCompIsPopulated(ws_comp) ? ws_comp._id : "";

        return (
          <div key={index}>
            {isResolvedSuggestion && (
              <ResolvedSuggestionActivityHeader user={user} date={comment_thread_id.updatedAt} />
            )}
            <InlineCommentThread
              className={isResolvedSuggestion ? style.resolvedSuggestionContainer : undefined}
              comp_id={wsCompIsPopulated(ws_comp) && ws_comp._id}
              isDisabled={false}
              ws_comp_id={wsCompId}
              isActive={activeCommentThreadId === comment_thread_id._id}
              comment_thread={comment_thread_id}
              onResolveSuggestion={onResolveSuggestion}
              handleCommentChangeClick={handleCommentChangeClick}
              handleHistoryUpdate={refreshLibraryHistory}
              handleCommentThreadClicked={handleCommentThreadClicked}
              handleScrollTo={handleScrollTo}
              workspaceUsers={workspaceUsers}
              updateCompResultComments={updateCompResultComments}
              isWsComp={true}
              handleDocUpdate={() => {
                // blank function because CompHistory is used
                // in Doc as well, and will break if left empty
                // in CompDetail component
              }}
              fetchCompInfo={() => {
                // blank function because CompHistory is used
                // in Doc as well, and will break if left empty
                // in CompDetail component
              }}
            />
          </div>
        );
      }
      // make sure that plurals-edited with doc values are filtered out
      if (entry_type === "plurals-edited") {
        if (doc_id) return null;
        let message;

        if (data?.added?.length && data?.removed?.length) {
          message = "Added and removed plural forms";
        } else if (data?.added?.length) {
          message = `Added plural form${data?.added?.length > 1 ? "s" : ""}`;
        } else if (data?.removed?.length) {
          message = `Removed plural form${data?.removed?.length > 1 ? "s" : ""}`;
        }

        return (
          <div
            key={index}
            className={style.version}
            onClick={() => wsCompIsPopulated(ws_comp) && openWsComp(ws_comp._id)}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div className={style.comp}>{message}</div>
            <div className={style.componentNameWrapper}>
              <span className={style.component}>{getWsCompName()}</span>
            </div>
          </div>
        );
      }
      if (entry_type === ENTRY_TYPES.WS_COMP_RENAME) {
        return (
          <div
            key={index}
            className={style.version}
            onClick={() => wsCompIsPopulated(ws_comp) && openWsComp(ws_comp._id)}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div className={style.comp}>
              Renamed{" "}
              <div className={style.componentNameWrapper}>
                <span className={style.component}>{data?.oldName || ""}</span>
              </div>{" "}
              to{" "}
              <div className={style.componentNameWrapper}>
                <span className={style.component}>{data?.newName || ""}</span>
              </div>
            </div>
          </div>
        );
      }
      if (entry_type === "ws-comp-assigned" || entry_type === "multi-ws-comp-assigned") {
        return (
          <div
            key={index}
            className={style.version}
            onClick={() => {
              if (entry_type === "ws-comp-assigned" && wsCompIsPopulated(ws_comp)) {
                openWsComp(ws_comp._id);
              }
            }}
          >
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div className={style.flexDistributed}>
              {data?.assignee !== null ? (
                <div className={style.compAssigned}>
                  Assigned text to <span>@{data?.assigneeName}</span>
                </div>
              ) : (
                <div>Unassigned text</div>
              )}
              {(dupe_ws_comp_ids?.length || 0) > 0 && (
                <span className={style.count}>{dupe_ws_comp_ids?.length ?? "some"}</span>
              )}
            </div>
          </div>
        );
      }

      if (entry_type === ENTRY_TYPES.WORKSPACE_SETTINGS_COMPONENT_API_ID_GENERATE_ON_RENAME) {
        const { value_after } = data;
        const verb = value_after ? "Enabled" : "Disabled";
        return (
          <div key={index} className={style.version}>
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div>
              {verb} automatic updating of IDs when updating the component name.{" "}
              <a href="/developers/configure-dev-ids" target="_blank" className={style.grayLink}>
                Learn more
              </a>
            </div>
          </div>
        );
      }
      if (entry_type === ENTRY_TYPES.WORKSPACE_SETTINGS_COMPONENT_API_ID_PREVENT_MANUAL_EDITS) {
        const { value_after } = data;
        const verb = value_after ? "Disabled" : "Enabled";
        return (
          <div key={index} className={style.version}>
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div>
              {verb} manual editing of component IDs.
              <br />
              <a href="/developers/configure-dev-ids" target="_blank" className={style.grayLink}>
                Learn more
              </a>
            </div>
          </div>
        );
      }
      if (entry_type === ENTRY_TYPES.WORKSPACE_SETTINGS_COMPONENT_API_ID_GENERATION_CONFIG) {
        return (
          <div key={index} className={style.version}>
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div>
              Updated component ID generation rules.{" "}
              <a href="/developers/configure-dev-ids" target="_blank" className={style.grayLink}>
                Learn more
              </a>
            </div>
          </div>
        );
      }
      if (entry_type === ENTRY_TYPES.COMPONENTS_IN_WORKSPACE_REGENERATED) {
        return (
          <div key={index} className={style.version}>
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            <div>
              Regenerated component IDs based on generation rules.{" "}
              <a href="/developers/configure-dev-ids" target="_blank" className={style.grayLink}>
                Learn more
              </a>
            </div>
          </div>
        );
      }

      if (entry_type === ENTRY_TYPES.COMPONENTS_MERGED) {
        const { targetComponent, mergedComponents } = data;

        return (
          <div key={index} className={style.version} onClick={() => handleChangeItemClick(changeItem)}>
            <div className={style.meta}>
              {user || "Unknown User"}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
            {mergedComponents.length === 1 && (
              <div>
                Merged <ComponentLabel inline name={mergedComponents[0].name} /> into{" "}
                <ComponentLabel inline name={targetComponent.name} />
              </div>
            )}
            {mergedComponents.length > 1 && (
              <div>
                Merged {mergedComponents.length} components into <ComponentLabel inline name={targetComponent.name} />
                <ul className={style.mergedComponentList}>
                  {mergedComponents.map((mc) => (
                    <li key={mc._id}>
                      <ComponentLabel inline name={mc.name} />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        );
      }

      if (entry_type === ENTRY_TYPES.AB_INTEGRATION_DISABLED || entry_type === ENTRY_TYPES.AB_INTEGRATION_ENABLED) {
        const enabled = entry_type === ENTRY_TYPES.AB_INTEGRATION_ENABLED;

        return (
          <div key={index} className={style.version} onClick={() => window.location.replace("/account/connections")}>
            <div className={style.meta}>
              {user || "Unknown User"}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>

            <div>
              {enabled ? "Enabled" : "Disabled"} connection with {data.platformName}
            </div>
          </div>
        );
      }
      if (entry_type === ENTRY_TYPES.WS_COMP_CHARACTER_LIMIT_UPDATE) {
        if (data.wsCompIds.length === 1) {
          return (
            <div
              key={index}
              className={style.version}
              onClick={() => wsCompIsPopulated(ws_comp) && openWsComp(ws_comp._id)}
            >
              <div className={style.meta}>
                {user || "Unknown User"}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
              </div>

              <div className={style.richTextChangeItem}>
                <DittoComponent
                  componentId="component-character-limit-change"
                  variables={{
                    characterLimit: data.newCharacterLimit,
                    count: 1,
                  }}
                  count={1}
                  richText
                />
              </div>
            </div>
          );
        }

        // Multiple ws_comps were updated at the same time
        return (
          <div key={index} className={classnames([style.version, style.disabled])}>
            <div className={style.meta}>
              {user || "Unknown User"}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>

            <div className={classnames([style.flexDistributed, style.richTextChangeItem])}>
              <DittoComponent
                componentId="component-character-limit-change"
                variables={{
                  characterLimit: data.newCharacterLimit,
                  count: data.wsCompIds.length,
                }}
                count={data.wsCompIds.length}
                richText
              />
              <span className={style.count}>{data.wsCompIds.length}</span>
            </div>
          </div>
        );
      }
      return (
        <div
          key={index}
          className={classnames({
            [style.version]: true,
            [style.disabled]: entry_type === "delete",
          })}
          onClick={() => goToDoc(doc_id, entry_type)}
        >
          {user && user.includes("FIGMA EDIT") ? (
            <div>
              <div className={style.meta}>
                Edited directly in Figma, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
              </div>
              <div className={style.syncName}>
                {"->"} Synced by {((user || "").match(/\(([^)]+)\)/) || [])[1]}
              </div>
            </div>
          ) : (
            <div className={style.meta}>
              {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
            </div>
          )}
          <div className={style.changeDetail}>
            {" " + getChangeAction(entry_type)}
            <span className={style.docName}>{doc_name}</span>
            {entry_type === "sync" ? " with Figma" : ""}
          </div>
        </div>
      );
    });
  } else {
    return <div className={style.emptyState}>{emptyText}</div>;
  }
};

export default ActivityColumn;

function Status(props) {
  const { status, children } = props;
  if (!(status && status !== "MIXED")) {
    return <React.Fragment />;
  }

  return (
    <div
      className={classnames({
        [style.text]: true,
        [style.status]: true,
        [style.change]: true,
        [style.statusNone]: status === "NONE",
        [style.statusWip]: status === "WIP",
        [style.statusReview]: status === "REVIEW",
        [style.statusFinal]: status === "FINAL",
      })}
    >
      {children}
    </div>
  );
}

export const User = (props) => {
  const { user, date } = props;

  return (
    <div className={style.meta}>
      {user ? `${user}, ` : ""}
      {date && <TimeAgo date={date} minPeriod={30} key={date.toString()} />}
    </div>
  );
};

export const ComponentLabel = (props) => {
  const { name, inline, type = "standard", fallbackText = "[DELETED COMPONENT]", textStyle } = props;

  return (
    <span
      className={classnames({
        [style.componentNameWrapper]: true,
        [style.inline]: !!inline,
        [style.componentNameWrapperTemplate]: type === "template",
      })}
    >
      <span className={style.component} style={textStyle}>
        {name || fallbackText}
      </span>
    </span>
  );
};

export const VariantLabel = (props) => {
  const { name, fallbackText = "[DELETED VARIANT]" } = props;

  return (
    <div className={style.variantNameWrapper}>
      <CallSplitIcon className={style.splitIconRotate} />
      <span className={style.variant}>{name || fallbackText}</span>
    </div>
  );
};

export const FolderChangeItem = (props) => {
  const { user, date, onClick, text, folderName } = props;

  return (
    <div className={classnames([style.version])} onClick={onClick}>
      <User user={user} date={date} />
      <div className={style.folderTextContainer}>
        <span>{text}</span>{" "}
        <span className={style.folderText}>
          <FolderIcon className={style.folderIcon} /> {folderName}
        </span>
      </div>
    </div>
  );
};
