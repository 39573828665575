import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps extends React.HTMLAttributes<HTMLSpanElement> {}

// TODO: typesafe text variations for different headers, font weights, etc
export function Text(props: IProps) {
  const { children, ...rest } = props;

  return (
    <span {...rest} className={classNames(style.TextWrapper, props.className)} data-testid="text">
      {children}
    </span>
  );
}

export default Text;
